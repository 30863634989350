import propTypes from 'prop-types';

function Status({ statusLiteral, className, component, showColor = true }) {
  const Component = component;
  if (statusLiteral === 'WENOWCALL') {
    return <Component className={`${className} ${showColor && 'color-blue-dark'}`}>Ahora te llamamos</Component>;
  } else if (statusLiteral === 'WEHAVECALLED') {
    return <Component className={`${className} ${showColor && 'color-blue-dark'}`}>Te hemos llamado</Component>;
  } else if (statusLiteral === 'INPROCESS') {
    return <Component className={`${className} ${showColor && 'color-green-dark'}`}>En proceso</Component>;
  } else if (statusLiteral === 'CLOSED') {
    return <Component className={`${className} ${showColor && 'color-red-dark'}`}>Cerrado</Component>;
  } else if (statusLiteral === 'SENT_CLOSED') {
    return <Component className={`${className} ${showColor && 'color-blue-dark'}`}>Gestionado</Component>;
  }
}

Status.defaultProps = {
  component: (props) => <span {...props} />
};

Status.propTypes = {
  statusLiteral: propTypes.string,
  className: propTypes.string,
  component: propTypes.func
};

export default Status;
