import Loader from '@components/common/Loader';
import SectionTitle from '@components/common/SectionTitle';
import Status from '@components/utils/Status';
import { URL_ELABOGADO } from '@constants';
import useApiFetch from '@hooks/api/useFetchApi';
import { privateRoutes } from '@routes';
import userService from '@services/User.service';
import '@styles/card.css';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';

function ListLeads({ fetchLeads: leads }) {
  const fetchLeads = !leads ? useApiFetch(userService.getLeads()) : leads;
  return fetchLeads.isLoading ? (
    <Loader />
  ) : (
    <>
      {fetchLeads.data.content.length > 0 && (
        <>
          <SectionTitle text="Listados de mis casos" />
          <div className="card card-style pb-3">
            {fetchLeads.data.content.map((lead, index) => {
              return <LeadItem key={index} lead={lead} />;
            })}
          </div>
        </>
      )}
    </>
  );
}

const LeadItem = ({ lead }) => {
  return (
    <Link to={`${privateRoutes.LEAD}/${lead.id}`} className="m-3 mb-0">
      <div className="d-flex">
        {(lead.lawyers && lead.lawyers.length > 0 && (
          <>
            <img
              src={`${URL_ELABOGADO}${lead.lawyers[0].urlPicture} `}
              width="40"
              height="40"
              className="rounded-sm me-2 mt-1"
            />
          </>
        )) || <></>}
        <div></div>
        <div className="ps-1">
          <h6 className="font-600 font-14 opacity-50 mt-1">{lead.parentSpecialty}</h6>
          <h6 className="mb-1">{lead.specialty}</h6>
        </div>
        <div className="ms-auto align-self-center text-end">
          <h6 className="font-500 font-14 opacity-50 mb-0">Estado</h6>
          <Status
            statusLiteral={lead.status}
            className="mb-1 fw-bold"
            component={(props) => <span {...props}></span>}
          />
        </div>
      </div>
    </Link>
  );
};

LeadItem.propTypes = {
  lead: propTypes.object
};
ListLeads.propTypes = {
  fetchLeads: propTypes.object
};

export default ListLeads;
