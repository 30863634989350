import useApi from '@hooks/api/useApi';
import { User } from '@models/User';
import { publicRoutes } from '@routes';
import authService from '@services/Auth.service';
import { useEffect, useState } from 'react';
import ReactInputVerificationCode from 'react-input-verification-code';
import { useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import registerFlow from 'utils/RegisterFlow';

function ValidateSms() {
  const validateSms = useApi();
  const MySwal = withReactContent(Swal);
  const [code, setCodeSMS] = useState('');
  const { state } = useLocation();
  const { phoneState, emailState, token, origin, tokenGoogle } = state || {};
  let navigate = useNavigate();

  function validateSmsCode(code) {
    validateSms.call(authService.validateSms(phoneState, code));
  }

  useEffect(() => {
    if (!validateSms.isLoading && validateSms.data && validateSms.data?.validPhone && code.length === 6) {
      setCodeSMS('');
      const { validPhone, phone } = validateSms.data;
      const user = User(
        null,
        state.user.idGoogle,
        emailState,
        state.user.name,
        phone,
        validPhone,
        state.user.photo,
        null
      );
      registerFlow(navigate, user, token, origin, tokenGoogle);
    }
    if (validateSms.error || (validateSms.data && !validateSms.data.validPhone)) {
      MySwal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Datos incorrectos, vuelva a intentar'
      }).then(() => {
        navigate(publicRoutes.LOGIN);
      });
    }
  }, [validateSms]);

  const pasteCode = () => {
    navigator.clipboard.readText().then((text) => {
      const regex = /^[0-9]{6}$/;
      if (!regex.test(text)) {
        MySwal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'El texto pegado no es un código de 6 dígitos'
        });
        return;
      }
      setCodeSMS(text);
    });
  };

  return (
    <div className="page-content header-clear-medium">
      <div className="card card-style">
        <div className="content">
          <p className="font-600 color-highlight mb-1">Código SMS</p>
          <p>
            En breve recibirás un SMS de <strong> elAbogado</strong> en el número de teléfono
            <b> {phoneState} </b> con un código para verificar tu identidad.
          </p>
          <div className="verificationCode">
            <ReactInputVerificationCode
              autoFocus="true"
              icon="fa-comment"
              length={6}
              onChange={setCodeSMS}
              onCompleted={validateSmsCode}
              value={code}
              label="Código de verificación"
              name="code"
              type="number"
              placeholder=""
            />
          </div>
          <div onClick={pasteCode} className="text-center pt-4  mt-3 p-3 font-500">
            Pegar
            <i className="fa-regular fa-copy ms-2"></i>
          </div>
          <button
            onClick={() => {
              validateSmsCode(code);
            }}
            style={{ width: '100%' }}
            type="submit"
            className={`btn btn-full btn-l font-600 font-13 gradient-highlight mt-3 rounded-s ${
              (validateSms.isLoading || code.length < 6) && 'disabled'
            }`}>
            Validar código
          </button>
        </div>
      </div>
    </div>
  );
}

export default ValidateSms;
