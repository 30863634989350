import CreateLeadCircuit from '@components/createLeadCircuit';
import '@styles/menu.css';
import './NewLead.css';
import propTypes from 'prop-types';

function ModalNewLead({ openQuestionsModal, updateModalState }) {
  return (
    <div
      className={`menu menu-box-modal rounded-0 new-lead-modal ${openQuestionsModal && 'menu-active'}`}
      data-menu-width="cover"
      data-menu-height="cover">
      <a
        onClick={(e) => {
          e.preventDefault();
          updateModalState(!openQuestionsModal);
        }}
        className="close-menu icon icon-xs rounded-xl bg-red-dark mt-3 float-end me-3">
        <i className="fa fa-times"></i>
      </a>
      <div className="card-center">
        <div className="content">
          <CreateLeadCircuit />
        </div>
      </div>
    </div>
  );
}

ModalNewLead.propTypes = {
  openQuestionsModal: propTypes.bool,
  updateModalState: propTypes.func
};

export default ModalNewLead;
