import useAuth from '@hooks/useAuth';
import propTypes from 'prop-types';
import { useState } from 'react';

const BASE_URL = process.env.REACT_APP_URL_PUBLIC_HOST;

function AddName({ updateMessage, idMessage }) {
  const [name, setName] = useState('');
  const [error, setError] = useState(null);
  const { updateUserData, authed } = useAuth();

  async function createLead() {
    const formData = new FormData();
    formData.append('message.idMessage', idMessage);
    formData.append('message.name', name);
    const resultRequest = await fetch(`${BASE_URL}/message/create/name`, {
      method: 'POST',
      body: formData
    });

    if (resultRequest && resultRequest.ok) {
      const { message } = await resultRequest.json();
      if (authed) {
        updateUserData({ name });
      }
      updateMessage(message);
    } else {
      setError('Error actualizando el  nombre');
    }
  }
  return (
    <>
      <h1 className="font-18">Indícanos tu nombre</h1>
      <div className="input-style has-borders has-icon validate-field mb-4">
        <i className="fa fa-user-tie"></i>
        <input
          type="text"
          className="form-control validate-text"
          id="form4"
          placeholder="Nombre"
          value={name}
          onChange={(e) => setName(e.target.value)}
          maxLength="15"
        />
        <label htmlFor="form4" className="color-highlight">
          Nombre
        </label>
        <i className={`fa fa-times ${!error && 'disabled'} invalid color-red-dark`}></i>
        <em className={name.length > 0 && 'disabled'}>(obligatorio)</em>
      </div>
      <div className="color-red-dark pl-2">{error}</div>

      <a
        onClick={createLead}
        className={`btn btn-full btn-l font-600 font-13 gradient-highlight mt-4 rounded-s ${
          name.length <= 0 && 'disabled'
        }`}>
        Siguiente
      </a>
    </>
  );
}

AddName.propTypes = {
  updateMessage: propTypes.func,
  idMessage: propTypes.number
};

export default AddName;
