import logoElAbogado from '@assets/img/logo-elAbogado.png';
import useAuth from '@hooks/useAuth';
import '@styles/menu.css';
import propTypes from 'prop-types';
import { createContext, useState } from 'react';
import { Link } from 'react-router-dom';
import './Sidebar.css';
import menuData from './menuData';

export const MenuSideBarContext = createContext();

function Sidebar({ children }) {
  const [isOpen, setIsOpen] = useState(false);
  const auth = useAuth();
  function updateStatusMenu(forceClose) {
    if (forceClose) {
      if (isOpen) {
        setIsOpen(false);
      }
    } else {
      setIsOpen(!isOpen);
    }
  }

  return (
    <MenuSideBarContext.Provider value={updateStatusMenu}>
      {children}
      <div
        id="menu-main"
        className={`menu menu-box-left rounded-0 ${isOpen && 'menu-active'} ${!auth.authed && 'd-none'}`}
        data-menu-width="280"
        data-menu-active="nav-pages"
        style={{ display: 'block', width: 280, overflowY: 'hidden' }}>
        <div className="card rounded-0" data-card-height="150" style={{ height: 150 }}>
          <div className="card-top">
            <a
              onClick={(e) => {
                e.preventDefault();
                updateStatusMenu();
              }}
              className="close-menu float-end p-3 text-center icon-40 notch-clear">
              <i className="fa-solid fa-circle-arrow-left fs-3"></i>
            </a>
          </div>
          <div className="card-center ">
            <a href="https://www.elabogado.com">
              <img src={logoElAbogado} width="280" className="px-4" />
            </a>
          </div>
        </div>
        <div className="mt-4"></div>
        <h6 className="menu-divider">Menú</h6>
        <div className="list-group list-custom-small list-menu">
          {menuData.map((menuitem, i) => {
            return (
              <Link key={i} to={menuitem.path}>
                {menuitem.icon}
                {menuitem.title}
                <i className="fa fa-angle-right"></i>
              </Link>
            );
          })}
        </div>

        <div className="box-privacy position-absolute bottom-0 btn-m text-start">
          <a
            onClick={(e) => {
              e.preventDefault();
              updateStatusMenu(true);
              auth.logout();
            }}>
            <i className="fa fa-arrow-right-from-bracket   "></i>
            <span role="button" aria-pressed="true">
              <u className="ms-2">Cerrar sesión</u>
            </span>
          </a>
          <footer className="politicaPriv">
            Al acceder al panel, acepto expresamente la
            <a target="_blank" rel="noreferrer" href="http://www.elabogado.com/privacidad/">
              {' '}
              Política de privacidad
            </a>{' '}
            y las
            <a target="_blank" rel="noreferrer" href="http://www.elabogado.com/condiciones-uso/">
              {' '}
              Condiciones de uso
            </a>
            .
          </footer>
        </div>
      </div>

      <div onClick={() => updateStatusMenu()} className={`menu-hider ${isOpen && 'menu-active'}`}></div>
    </MenuSideBarContext.Provider>
  );
}

Sidebar.propTypes = {
  children: propTypes.array
};

export default Sidebar;
