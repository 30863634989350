import TextInput from '@components/common/Form/TextInput';
import { Form, Formik } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';
import registerFlow from 'utils/RegisterFlow';
import LoginGoogle from '@components/account/LoginGoogle';
import * as Yup from 'yup';
import { User } from '@models/User';

function ValidateEmail() {
  const navigate = useNavigate();

  const { state } = useLocation();
  const { phoneValidated, phoneState, token, origin, tokenGoogle } = state || {};

  function createUser(data) {
    const user = User(null, null, data.email, state.user.name, phoneState, phoneValidated, null, null, null);
    registerFlow(navigate, user, token, origin, tokenGoogle);
  }

  return (
    <>
      <div className="header-clear-medium"></div>
      <div className="card card-style card-mw-500 mx-auto">
        <div className="content">
          <h2 className="text-start mb-4">Completa tu registro</h2>
          <Formik
            initialValues={{
              email: ''
            }}
            validationSchema={Yup.object({
              email: Yup.string().email('Invalid email address').required('Required')
            })}
            onSubmit={createUser}>
            <Form>
              <TextInput icon="fa-at" label="Email" name="email" type="email" placeholder="Email" />

              <button
                style={{ width: '100%' }}
                type="submit"
                className={`btn btn-full btn-l font-600 font-13 gradient-highlight mt-4 rounded-s `}>
                Continuar
              </button>
              <div className="text-center my-3 fw-bold">O continuar con</div>
              <LoginGoogle login={{}} title="Google" state={state} />
            </Form>
          </Formik>
          <div className="row pt-3 mb-3">
            <div className="col-6 text-start font-11"></div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ValidateEmail;
