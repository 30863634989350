export const User = (
  id,
  idGoogle,
  email,
  name,
  phone,
  phoneValidated,
  photo,
  description,
  tokenOTP
) => {
  return {
    id: id,
    idGoogle: idGoogle,
    email: email,
    name: name,
    phone: phone,
    phoneValidated: phoneValidated,
    photo: photo,
    description: description,
    tokenOTP: tokenOTP
  };
};
