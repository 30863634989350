import { useEffect, useState } from 'react';

const BASE_URL = process.env.REACT_APP_URL_PUBLIC_HOST;
const DEFAULT_LOCALE = process.env.REACT_APP_DEFAULT_LOCALE;

export default function useFetchPublicApi(url) {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const resultRequest = await fetch(
          `${BASE_URL}${url}?` +
            new URLSearchParams({
              request_locale: DEFAULT_LOCALE
            })
        );
        if (resultRequest && resultRequest.ok) {
          const response = await resultRequest.json();
          setData(response);
        } else {
          setError(true);
          //TODO:set error API
          setError('General Error');
        }
      } catch (error) {
        setError(true);
        setErrorMessage(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [url]);

  return { data, isLoading, error, errorMessage };
}
