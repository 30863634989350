import axios from 'axios';
import jwt_decode from 'jwt-decode';

const BASE_URL = process.env.REACT_APP_URL_MICROSERVICES;

export const axiosPublic = axios.create({ baseURL: `${BASE_URL}/v1/public` });
export const axiosAuth = axios.create({ baseURL: `${BASE_URL}` });
export const axiosPrivate = axios.create({ baseURL: `${BASE_URL}` });

axiosPrivate.interceptors.request.use(
  async (config) => {
    const userAuth = JSON.parse(window.localStorage.getItem('userAuth'));
    if (userAuth) {
      const tokenDecode = jwt_decode(userAuth.token);
      if (new Date(tokenDecode.exp * 1000) <= new Date().getTime()) {
        const refreshed = await axiosAuth.post('/oauth/refresh', null, {
          headers: { Authorization: `Bearer   ${userAuth.refreshToken}` }
        });
        if (refreshed && refreshed.status === 201) {
          config.headers['Authorization'] = `Bearer  ${refreshed.data.token}`;
          window.localStorage.setItem(
            'userAuth',
            JSON.stringify({
              token: refreshed.data.token,
              refreshToken: refreshed.data.refreshToken,
              phone: userAuth.phone,
              userData: { ...userAuth.userData }
            })
          );
        }
      } else {
        config.headers['Authorization'] = `Bearer  ${userAuth.token}`;
      }
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
