import BasicDetails from '@components/account/BasicDetails';
import PrivatePage from '@components/common/PrivatePage';
import CarouselLeads from '@components/myLeads/CarouselLeads';

export default function Account() {
  return (
    <>
      <PrivatePage title="Mis Datos">
        <div className="page-content">
          <BasicDetails />
          <CarouselLeads urlLeads="/leads" title="Todos mis casos" />
        </div>
      </PrivatePage>
    </>
  );
}
