import propTypes from "prop-types";
import "@styles/button.css";

function Button({ href, className, text }) {
    return (<a href={href} className={className}>{text}</a>
    );
}


Button.propTypes = {
    href: propTypes.string,
    className: propTypes.string,
    text: propTypes.string,
}
export default Button;