import useAuth from '@hooks/useAuth';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AddEmail from './steps/AddEmail';
import AddLocality from './steps/AddLocality';
import AddName from './steps/AddName';
import AddPhone from './steps/AddPhone';
import AddSpecialty from './steps/AddSpecialty';
import AddText from './steps/AddText';
import SpecialtyQuestions from './steps/SpecialtyQuestions';

function CreateLeadCircuit() {
  const { authed } = useAuth();
  let navigate = useNavigate();

  const [circuitData, setCircuitData] = useState({
    message: null,
    hasRecentMessage: false,
    firstQuestion: null
  });
  const [questionsSpecialtyCompleted, setQuestionsSpecialtyCompleted] = useState(false);
  const [skipSpecialty, setSkipSpecialty] = useState(false);
  const notifyHasRecentMessage = () => {
    setCircuitData({ ...circuitData, hasRecentMessage: true });
  };
  const updateMessageInfo = (newMessageData) => {
    setCircuitData({ ...circuitData, message: newMessageData });
  };
  const setMessageAndQuestion = (message, question) => {
    setCircuitData({ ...circuitData, firstQuestion: question, message });
  };

  function returnQuestionByMessageStatus() {
    if (circuitData.hasRecentMessage) {
      return (
        <h2 className="line-height-xl text-center">
          Te llamamos ahora mismo <br />
          Un abogado ya está gestionando tu petición.
        </h2>
      );
    } else if (!authed && !circuitData.message) {
      return <AddPhone callback={setMessageAndQuestion} callbackRecentMessage={notifyHasRecentMessage} />;
    } else if (!circuitData.message || (!circuitData.message.idSpecialty && !skipSpecialty)) {
      return (
        <AddSpecialty
          message={circuitData.message}
          callback={setMessageAndQuestion}
          callbackRecentMessage={notifyHasRecentMessage}
          skipSpecialty={setSkipSpecialty}
        />
      );
    } else if (circuitData.firstQuestion && !questionsSpecialtyCompleted) {
      return (
        <SpecialtyQuestions
          idMessage={circuitData.message.idMessage}
          firstQuestion={circuitData.firstQuestion}
          callbackFinish={() => {
            setQuestionsSpecialtyCompleted(true);
          }}
        />
      );
    } else if (!circuitData.message.idLocation) {
      return <AddLocality updateMessage={updateMessageInfo} idMessage={circuitData.message.idMessage} />;
    } else if (!circuitData.message.name) {
      return <AddName updateMessage={updateMessageInfo} idMessage={circuitData.message.idMessage} />;
    } else if (!circuitData.message.text) {
      return <AddText updateMessage={updateMessageInfo} idMessage={circuitData.message.idMessage} />;
    } else if (!circuitData.message.email) {
      return <AddEmail updateMessage={updateMessageInfo} idMessage={circuitData.message.idMessage} />;
    } else {
      return (
        <>
          <h1 className="font-18">
            {circuitData.message.name}, te llamamos ahora mismo <br />
            Un abogado ya está gestionando tu petición.
          </h1>
          <a
            onClick={async (e) => {
              e.preventDefault();
              const resultRequest = await fetch(
                `${process.env.REACT_APP_URL_MICROSERVICES}/v1/public/users/send-code/${circuitData.message.phone}`
              );

              if (resultRequest && resultRequest.ok) {
                navigate(`/sms/${circuitData.message.phone}`, { replace: true });
              }
            }}
            className={`btn btn-full btn-l font-600 font-13 gradient-highlight mt-4 rounded-s`}>
            Quiero ver mi caso
          </a>
        </>
      );
    }
  }

  return returnQuestionByMessageStatus();
}

export default CreateLeadCircuit;
