import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useApi from '@hooks/api/useApi';
import userService from '@services/User.service';
import MessageIcon from '@components/common/MessageIcon';
import useAuth from '@hooks/useAuth';
import Errors from 'utils/Errors';

export function ValidateChangeEmail() {
  const auth = useAuth();
  const api = useApi();
  const [changeEmail, setChangeEmail] = useState(false);
  const [icon, setIcon] = useState('loading');
  const [message, setMessage] = useState('loading');
  const { token, type } = useParams();
  const error = Errors();
  let actionEmail = 0;
  useEffect(() => {
    if (!api.isLoading && !api.error && !changeEmail) {
      if (type == 'change') {
        actionEmail = 1;
      } else if (type == 'rollback') {
        actionEmail = 2;
      }
      setChangeEmail(true);
      api.call(userService.setEmail(token, actionEmail));
    }

    if (!api.isLoading && !api.error && api.data && icon == 'loading') {
      if (api.data.status == 'ok') {
        if (type == 'change') {
          setIcon('changeEmailSuccess');
          setMessage('changeEmailSuccess');
        } else if (type == 'rollback') {
          setIcon('rollbackEmailSuccess');
          setMessage('rollbackEmailSuccess');
        }
        auth.updateUserData({ email: api.data.email });
      } else {
        error.generic();
      }
    }

    if (api.error) {
      setIcon('error');
      setMessage('changeEmailError');
    }
  }, [api]);

  return (
    <div className={`page-content ${auth.token ? 'header-clear-small' : 'header-clear-medium'}`}>
      <MessageIcon icon={icon} message={message} />
    </div>
  );
}

export default ValidateChangeEmail;
