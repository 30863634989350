import { useState } from 'react';
import propTypes from 'prop-types';
import useAuth from '@hooks/useAuth';
import LocationSearch from '@components/common/LocationSearch';

const BASE_URL = process.env.REACT_APP_URL_PUBLIC_HOST;

function AddLocality({ updateMessage, idMessage }) {
  const { updateUserData, authed, userData } = useAuth();

  const [locationName, setLocationName] = useState('');
  const [idLocation, setIdLocation] = useState(
    authed && userData.location.id ? userData.location.id : null
  );
  const [error, setError] = useState(null);

  async function updateLocation() {
    const formData = new FormData();
    formData.append('message.idMessage', idMessage);
    formData.append('message.idLocation', idLocation);
    const resultRequest = await fetch(`${BASE_URL}/message/create/locality`, {
      method: 'POST',
      body: formData
    });
    if (resultRequest && resultRequest.ok) {
      const { message } = await resultRequest.json();
      if (authed) {
        updateUserData({ location: { id: idLocation, name: locationName } });
      }
      updateMessage(message);
    } else {
      setError('Error actualizando la localidad');
    }
  }

  return (
    <>
      <h1 className="font-18">¿En qué ciudad necesitas abogado?</h1>
      <LocationSearch
        value={authed ? userData.location : {}}
        callback={({ id, name }) => {
          setLocationName(name);
          setIdLocation(id);
        }}
      />
      <div className="color-red-dark pl-2">{error}</div>
      <a
        onClick={updateLocation}
        className={`btn btn-full btn-l font-600 font-13 gradient-highlight mt-4 rounded-s ${
          !idLocation && 'disabled'
        }`}>
        Siguiente
      </a>
    </>
  );
}

AddLocality.propTypes = {
  updateMessage: propTypes.func,
  idMessage: propTypes.number
};

export default AddLocality;
