import useAuth from '@hooks/useAuth';
import { privateRoutes } from '@routes';
import { Link } from 'react-router-dom';

function BasicDetails() {
  const { userData } = useAuth();
  return (
    <div className="card card-style">
      <div className="d-flex content mb-1">
        <div className="flex-grow-1">
          <h2>{userData.name ? userData.name : 'Mi nombre'}</h2>
          <p className="mb-2 mt-3">{` ${userData.description}`} </p>
        </div>
        {userData.photo && userData.photo != 'default/user.png' ? (
          <div className="d-flex align-items-center">
            <img
              src={userData.photo}
              width="100"
              height="100"
              className="rounded-sm mx-3 border border-3 border-dark"
            />
          </div>
        ) : (
          <div className="mx-3 d-flex align-items-center justify-content-center">
            <i className="far fa-6x fa-user-circle shadow-xl rounded-circle"></i>
          </div>
        )}
      </div>
      <div className="content mb-0">
        <div className="row mb-0">
          <div className="col-6">
            <Link
              to={privateRoutes.EDIT_PROFILE}
              className="btn btn-full btn-sm rounded-s font-600 font-13 bg-highlight">
              Editar
            </Link>
          </div>
        </div>
      </div>

      <div className="divider mt-4 mb-0"></div>
    </div>
  );
}

export default BasicDetails;
