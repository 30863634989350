import React from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import * as Sentry from '@sentry/react';
import propTypes from 'prop-types';
import { privateRoutes } from '@routes';

function SentryErrorBoundary({ children }) {
  const MySwal = withReactContent(Swal);

  return (
    <Sentry.ErrorBoundary
      fallback={() => {
        MySwal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Ocurrio un error procesando tu petición'
        }).then(() => {
          window.location = privateRoutes.HOME;
        });
      }}>
      {children}
    </Sentry.ErrorBoundary>
  );
}

SentryErrorBoundary.propTypes = {
  children: propTypes.oneOfType([propTypes.object, propTypes.array])
};

export default SentryErrorBoundary;
