import useAuth from '@hooks/useAuth';
import { privateRoutes } from '@routes';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { MenuSideBarContext } from '../Sidebar';
import './Footer.css';

function Footer() {
  const { authed } = useAuth();
  const updateStatusMenu = useContext(MenuSideBarContext);
  return (
    authed && (
      <div id="footer-bar" className="footer-bar-6">
        <Link to="/mis-casos">
          <i className="fa fa-layer-group"></i>
          <span>Casos</span>
        </Link>
        <Link to="/nuevo-caso">
          <i className="fa fa-file"></i>
          <span>Nuevo</span>
        </Link>
        <Link to="/" className="circle-nav">
          <i className="fa fa-home"></i>
          <span>Inicio</span>
          <strong>
            <u></u>
          </strong>
        </Link>
        <Link to={privateRoutes.PROFILE}>
          <i className="fa fa-id-card"></i>
          <span>Perfil</span>
        </Link>
        <Link to={'#'} data-menu="menu-main" onClick={() => updateStatusMenu()}>
          <i className="fa fa-bars"></i>
          <span>Menu</span>
        </Link>
      </div>
    )
  );
}

export default Footer;
