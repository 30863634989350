import { useState } from 'react';
import propTypes from 'prop-types';

const BASE_URL = process.env.REACT_APP_URL_PUBLIC_HOST;

function AddText({ updateMessage, idMessage }) {
  const [text, setText] = useState('');
  const [error, setError] = useState(null);
  async function updateText() {
    const formData = new FormData();
    formData.append('message.idMessage', idMessage);
    formData.append('message.text', text);
    const resultRequest = await fetch(`${BASE_URL}/message/create/text`, {
      method: 'POST',
      body: formData
    });

    if (resultRequest && resultRequest.ok) {
      const { message } = await resultRequest.json();
      updateMessage(message);
    } else {
      setError('Error actualizando el  nombre');
    }
  }
  return (
    <>
      <h1 className="font-18">Cuéntanos más detalles sobre tu caso</h1>
      <div className="input-style has-borders has-icon validate-field mb-4">
        <textarea id="form7" value={text} onChange={(e) => setText(e.target.value)} />
        <i className={`fa fa-times ${!error && 'disabled'} invalid color-red-dark`}></i>
        <em className={name.length > 0 && 'disabled'}>(opcional)</em>
      </div>
      <div className="color-red-dark pl-2">{error}</div>

      <a
        onClick={updateText}
        className="btn btn-full btn-l font-600 font-13 gradient-highlight mt-4 rounded-s">
        Siguiente
      </a>
    </>
  );
}
AddText.propTypes = {
  updateMessage: propTypes.func,
  idMessage: propTypes.number
};
export default AddText;
