import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import '@assets/css/global.css';
import App from '@src/App';
import reportWebVitals from '@src/reportWebVitals';
import { AuthProvider } from '@state/AppContext';
import { initializeSentry } from '@components/utils/Sentry';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);
if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
  initializeSentry();
}

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <App />
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>,

  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
serviceWorkerRegistration.register(null, MySwal);
