import useApi from '@hooks/api/useApi';
import { privateRoutes, publicRoutes } from '@routes';
import userService from '@services/User.service';
import { useEffect, useState } from 'react';
import { useGoogleLogout } from 'react-google-login';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const LOCAL_STORAGE_AUTH = 'userAuth';
const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

export default function useProvideAuth() {
  const userPersisted = JSON.parse(window.localStorage.getItem('userAuth'));
  const [authed, setAuthed] = useState(userPersisted);
  const userInforApi = useApi();
  const syncLeadsApi = useApi();
  const MySwal = withReactContent(Swal);

  const updateUserData = (newUserData) => {
    let userAuthedPersisted = JSON.parse(window.localStorage.getItem('userAuth'));
    const newObjectUserData = { ...userAuthedPersisted.userData, ...newUserData };
    const phone = newUserData.phone ? newUserData.phone : userAuthedPersisted.phone;
    const newAuth = {
      token: userAuthedPersisted.token,
      refreshToken: userAuthedPersisted.refreshToken,
      phone,
      userData: newObjectUserData
    };
    window.localStorage.setItem(LOCAL_STORAGE_AUTH, JSON.stringify(newAuth));
    setAuthed(newAuth);
  };

  const onLogoutSuccess = () => {};
  const onLogoutFailure = () => {};

  const { signOut } = useGoogleLogout({
    clientId,
    onLogoutSuccess: onLogoutSuccess,
    onFailure: onLogoutFailure
  });

  useEffect(() => {
    if (!userInforApi.isLoading && !userInforApi.error && userInforApi.data) {
      if (!authed || !authed?.phone) {
        const userInfo = userInforApi.data;
        updateUserData(userInfo);
      }
    }
  }, [userInforApi]);

  const navigate = useNavigate();
  return {
    authed,
    login(token, refreshToken, phone, existingUser, userInfo) {
      if (token) {
        window.localStorage.setItem(
          LOCAL_STORAGE_AUTH,
          JSON.stringify({
            token,
            refreshToken,
            phone,
            userData: {
              phone,
              email: '',
              name: '',
              description: '',
              photo: '',
              location: {
                id: null,
                name: null
              },
              existingUser
            }
          })
        );

        setAuthed(JSON.parse(window.localStorage.getItem(LOCAL_STORAGE_AUTH)));
        if (existingUser && !userInfo) {
          userInforApi.call(userService.getUser());
          syncLeadsApi.call(userService.syncLeads());
        } else if (userInfo) {
          updateUserData(userInfo);
        }
        navigate(privateRoutes.HOME);
      } else {
        MySwal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Datos incorrectos, vuelva a intentar'
        }).then(() => {
          window.location = publicRoutes.LOGIN;
        });
      }
    },
    logout() {
      signOut();
      setAuthed(null);
      userInforApi.setData(null);
      window.localStorage.removeItem(LOCAL_STORAGE_AUTH);
      navigate(publicRoutes.LOGIN);
    },
    token: authed && authed.token,
    phone: authed && authed.phone,
    userData: authed && authed.userData,
    updateUserData
  };
}
