import Loader from '@components/common/Loader';
import PrincipalDetail from '@components/leadDetail/PrincipalDetail';
import ResumeLead from '@components/leadDetail/ResumeLead';
import { useParams } from 'react-router-dom';
import userService from '@services/User.service';
import useApiFetch from '@hooks/api/useFetchApi';

function LeadDetail() {
  const { idLead } = useParams();
  const fetchLead = useApiFetch(userService.getLead(idLead));

  return fetchLead.isLoading ? (
    <Loader />
  ) : (
    <>
      <PrincipalDetail infoLead={fetchLead.data} />
      <div className="page-content pb-4">
        <ResumeLead infoLead={fetchLead.data} />
      </div>
    </>
  );
}

export default LeadDetail;
