import { axiosPrivate, axiosPublic } from '@apiClients';

function getLead(idLead) {
  return () => axiosPrivate.get(`/v1/users/lead/${idLead}`);
}

function getLeads() {
  const params = new URLSearchParams({ page: 0, size: 20, sort: 'creationDate,desc' });
  return () => axiosPrivate.get(`/v1/users/leads?${params.toString()}`);
}

function getLeadsByLawyerId(idLawyer) {
  const params = new URLSearchParams({ page: 0, size: 20, sort: 'creationDate,desc' });
  return () => axiosPrivate.get(`/v1/users/leads/lawyer/${idLawyer}?${params.toString()}`);
}

function getUser() {
  return () => axiosPrivate.get(`/v1/users/`);
}

function sendEdit({ name, description }) {
  return () => axiosPrivate.put(`/v1/users/`, { name, description });
}

function register(name, photo, phone, email, phoneValidated, idGoogle, token, origin, tokenGoogle) {
  return () =>
    axiosPublic.post(
      `/users`,
      { idGoogle, name, photo, phone, email, phoneValidated },
      {
        headers: {
          'token-validate': token ? token : '',
          'token-origin': origin,
          'token-G': tokenGoogle
        }
      }
    );
}

function syncLeads() {
  return () => axiosPrivate.get(`/v1/users/leads/sync/`);
}

function changeEmail(email) {
  return () => axiosPrivate.put(`/v1/users/email`, { email });
}

function setEmail(token, type) {
  return () => axiosPublic.get(`/users/email/${token}`, { headers: { type } });
}

function setPhone(phone, tokenOTP) {
  return () => axiosPrivate.put(`/v1/users/phone`, { phone, tokenOTP });
}

export default {
  getLead,
  getLeads,
  getLeadsByLawyerId,
  getUser,
  sendEdit,
  register,
  syncLeads,
  changeEmail,
  setEmail,
  setPhone
};
