import { URL_ELABOGADO } from '@constants';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';

const LawyerCard = ({ lawyerInfo, widthImage, rightComplement }) => {
  return (
    <>
      <Link to={`/abogado/${lawyerInfo.id}`} className="d-flex">
        <div className="d-flex align-items-center">
          <img
            src={`${URL_ELABOGADO}${lawyerInfo.urlPicture}`}
            width={widthImage}
            className="rounded-sm bg-red-dark me-3"
          />
        </div>
        <div>
          <h5 className="mb-1">{lawyerInfo.name}</h5>
          <p className="font-500 mb-0 color-blue-dark">Ver ficha del abogado</p>
        </div>
        {rightComplement}
      </Link>
    </>
  );
};

LawyerCard.defaultProps = {
  widthImage: 40,
  rightComplement: () => {
    return (
      <div className="ms-auto pt-1">
        <span className="icon icon-m color-gray-dark me-n2 opacity-50">
          <i className="fa fa-cog font-18"></i>
        </span>
      </div>
    );
  }
};

LawyerCard.propTypes = {
  lawyerInfo: propTypes.object,
  widthImage: propTypes.number,
  rightComplement: propTypes.func
};

export default LawyerCard;
