import MessageIcon from '@components/common/MessageIcon';
import PrivatePage from '@components/common/PrivatePage';
import useApi from '@hooks/api/useApi';
import useAuth from '@hooks/useAuth';
import userService from '@services/User.service';
import Errors from '@utils/Errors';
import { useEffect, useState } from 'react';
import ReactInputVerificationCode from 'react-input-verification-code';
import { useLocation } from 'react-router-dom';

function ValidateChangePhone() {
  const { updateUserData } = useAuth();
  const api = useApi();
  const { state } = useLocation();
  const { phone } = state || {};
  const [code, setCodeSMS] = useState('');
  const [changePhone, setChangePhone] = useState(false);
  const [icon, setIcon] = useState('loading');
  const [message, setMessage] = useState('loading');
  const error = Errors();

  function validateSmsCode(code) {
    api.call(userService.setPhone(phone, code));
  }

  useEffect(() => {
    if (icon == 'loading') {
      if (!api.isLoading && api.data) {
        setIcon('changePhoneSuccess');
        setMessage('changePhoneSuccess');
        setChangePhone(true);
        updateUserData({ phone: api.data.phone });
      }
      if (api.error || (api.data && api.data.error)) {
        setIcon('error');
        setMessage('changeEmailError');
        setChangePhone(true);
      }
    }
  }, [api]);

  const pasteCode = () => {
    navigator.clipboard.readText().then((text) => {
      const regex = /^[0-9]{6}$/;
      if (!regex.test(text)) {
        error.build('error', 'Oops...', 'El texto pegado no es un código de 6 dígitos');
        return;
      }
      setCodeSMS(text);
    });
  };

  return (
    <PrivatePage title="Modificar télefono">
      <div className="page-content">
        {changePhone ? (
          <MessageIcon icon={icon} message={message} />
        ) : (
          <div className="card card-style card-mw-500 mx-sm-auto">
            <div className="content">
              <p className="font-600 color-highlight mb-1">Código SMS</p>
              <p>
                En breve recibirás un SMS de <strong> elAbogado</strong> en el número de teléfono
                <b> {phone} </b> con un código para verificar tu identidad y confirmar el cambio de número.
              </p>
              <div className="verificationCode">
                <ReactInputVerificationCode
                  autoFocus="true"
                  icon="fa-comment"
                  length={6}
                  onChange={setCodeSMS}
                  onCompleted={validateSmsCode}
                  value={code}
                  label="Código de verificación"
                  name="code"
                  type="number"
                  placeholder=""
                />
              </div>
              <div onClick={pasteCode} className="text-center pt-4  mt-3 p-3 font-500">
                Pegar
                <i className="fa-regular fa-copy ms-2"></i>
              </div>
              <button
                onClick={() => {
                  validateSmsCode(code);
                }}
                style={{ width: '100%' }}
                type="submit"
                className={`btn btn-full btn-l font-600 font-13 gradient-highlight mt-4 rounded-s ${
                  (api.isLoading || code.length < 6) && 'disabled'
                }`}>
                Validar código
              </button>
            </div>
          </div>
        )}
      </div>
    </PrivatePage>
  );
}

export default ValidateChangePhone;
