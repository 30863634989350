import propTypes from 'prop-types';

function SectionTitle({ text }) {
  return (
    <div className="mt-4 mb-2 ms-3">
      <div className="d-flex">
        <div className="align-self-center">
          <h4 className="mb-3 ms-2">{text}</h4>
        </div>
        <div className="ms-auto align-self-center"></div>
      </div>
    </div>
  );
}

SectionTitle.propTypes = {
  text: propTypes.string
};

export default SectionTitle;
