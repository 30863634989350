import Loader from '@components/common/Loader';
import PrivatePage from '@components/common/PrivatePage';
import CTA from '@components/lawyer/CTA';
import Description from '@components/lawyer/Descripton';
import CarouselLeads from '@components/myLeads/CarouselLeads';
import useApiFetch from '@hooks/api/useFetchApi';
import lawyerService from '@services/Lawyer.service';
import { useParams } from 'react-router-dom';
import userService from 'services/User.service';

function Lawyer() {
  const { lawyer } = useParams();
  const lawyerApi = useApiFetch(lawyerService.getLawyer(lawyer));
  const userApi = useApiFetch(userService.getLeadsByLawyerId(lawyer));
  return (
    <PrivatePage title="Ficha Abogado">
      <div className="page-content">
        {lawyerApi.isLoading ? (
          <Loader />
        ) : (
          <>
            <div className="card card-style pt-4">
              <CTA lawyerInfo={lawyerApi.data} />
              <Description lawyerInfo={lawyerApi.data} />
            </div>
            <CarouselLeads fetchLeads={userApi} title={`Mis casos con ${lawyerApi.data.name}`} />
          </>
        )}
      </div>
    </PrivatePage>
  );
}

export default Lawyer;
