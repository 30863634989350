import TextInput from '@components/common/Form/TextInput';
import LocationSearch from '@components/common/LocationSearch';
import useApi from '@hooks/api/useApi';
import useAuth from '@hooks/useAuth';
import { privateRoutes } from '@routes';
import userService from '@services/User.service';
import '@styles/list.css';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import * as Yup from 'yup';

function FormAccount() {
  const { userData, updateUserData } = useAuth();
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const sendEditApi = useApi();
  const MySwal = withReactContent(Swal);
  let navigate = useNavigate();

  async function sendEdit({ name, description }) {
    setUpdateSuccess(false);
    sendEditApi.call(userService.sendEdit({ name, description }));
  }

  useEffect(() => {
    if (!updateSuccess && !sendEditApi.isLoading && sendEditApi.data && !sendEditApi.error) {
      const { data } = sendEditApi;

      MySwal.fire({
        icon: 'success',
        title: '',
        text: 'OK'
      }).then(() => {
        setUpdateSuccess(true);
        updateUserData(data);
        navigate(privateRoutes.PROFILE);
      });
    }
    if (sendEdit.error) {
      MySwal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Datos incorrectos, vuelva a intentar'
      }).then(() => {
        window.location.reload();
      });
    }
  }, [sendEditApi]);
  return (
    <div className="card card-style">
      <div className="content">
        <p className="mb-1 color-highlight font-600">Editar mis datos</p>
        <h4>Información básica</h4>
        <p>
          Aquí puedes editar tus datos. Los datos de contacto se mandarán a los abogados cuando contactes con ellos.
        </p>
        <Formik
          initialValues={{
            name: userData.name ? userData.name : '',
            email: userData.email ? userData.email : '',
            phone: userData.phone ? userData.phone : '',
            description: userData.description ? userData.description : ''
          }}
          validationSchema={Yup.object({
            name: Yup.string().max(50, `Máximo 50 caracteres`).required('Required'),
            email: Yup.string().email(),
            description: Yup.string().max(250, `Máximo 50 caracteres`)
          })}
          onSubmit={sendEdit}>
          <Form>
            <TextInput icon="fa fa-user" placeholder="Tu nombre" type="text" label="Nombre" required name="name" />
            <LocationSearch
              value={userData.location}
              callback={({ id, name }) => {
                updateUserData({
                  location: {
                    id,
                    name
                  }
                });
              }}
            />
            <TextInput
              icon="far fa-comment-alt"
              placeholder="Descripcíon"
              name="description"
              type="text"
              label="Descripcíon"
            />

            <button
              style={{ width: '100%' }}
              type="submit"
              className={`btn btn-full btn-l font-600 font-13 gradient-highlight my-4 rounded-s ${
                sendEditApi.isLoading && 'disabled'
              }`}>
              Guardar
            </button>

            <div className="list-group list-custom-large">
              <Link to={privateRoutes.PROFILE_EMAIL}>
                <i className="fa font-18 fa-at color-blue-dark"></i>
                <span>{userData.email ? userData.email : 'Añadir correo eléctronico'}</span>
                <i className="fa fa-angle-right"></i>
              </Link>
              <Link to={privateRoutes.PROFILE_PHONE}>
                <i className="fa font-18 fa-phone color-green-dark"></i>
                <span>{userData.phone ? userData.phone : 'Añadir número de teléfono'}</span>
                <i className="fa fa-angle-right"></i>
              </Link>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
}

export default FormAccount;
