import '@assets/css/datepicker.css';
import useApi from '@hooks/api/useApi';
import { publicRoutes } from '@routes';
import dayjs from 'dayjs';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import ValidatePhone from './ValidatePhone';

dayjs.extend(isTomorrow);
const BASE_URL = process.env.REACT_APP_URL_PUBLIC_HOST;

const HOURS_WORK = { start: 8, end: 21 };

export default function ScheduleCall() {
  const [searchParams] = useSearchParams();
  const [showTimePicker, setShowTimePicker] = useState(false);
  const [customTime, setCustomTime] = useState(null);
  const [labelTime, setLabelTime] = useState(null);
  const [callScheduled, setCallScheduled] = useState(false);
  const [finalDataSelected, setFinalDataSelected] = useState(null);
  const [phoneValidate, setPhoneValidate] = useState(false);
  const navigate = useNavigate();
  const token = searchParams.get('token');
  const updatePhone = useApi();

  const scheduleCustomHour = async () => {
    await scheduleCallToMessage(customTime);
  };

  const getCurrentTimePlusOneHour = () => {
    const now = dayjs();
    const endOfWorkday = dayjs().clone().hour(HOURS_WORK.end).minute(0);
    const startOfNextDay = dayjs().clone().add(1, 'day').hour(HOURS_WORK.start).minute(0);

    if (now.isBefore(endOfWorkday)) {
      return ['Hoy', now.add(1, 'hour').minute(0)];
    } else if (now.isBefore(startOfNextDay)) {
      return ['Mañana', startOfNextDay];
    } else {
      return ['Mañana', startOfNextDay.add(1, 'hour')];
    }
  };

  const scheduleCallToMessage = async (dateSelected) => {
    let date = dayjs().format('YYYY-MM-DDTHH:mm:ss');
    if (dateSelected) {
      date = dayjs(dateSelected).format('YYYY-MM-DDTHH:mm:ss');
    }

    const resultRequest = await fetch(
      `${BASE_URL}/message/cannot-contact-user/call-me?token=${token}&dateCall=${date}`
    );
    if (resultRequest && resultRequest.ok) {
      const message = await resultRequest.json();
      if (message && message.idMessage) {
        if (message.released) {
          toast.error('Su caso ya ha sido gestionado');
          navigate(publicRoutes.LOGIN, {
            replace: true
          });
          return;
        } else {
          setFinalDataSelected(dayjs(date));
          setCallScheduled(true);
        }
      } else {
        toast.error('No se ha podido programa la llamada');
      }
    } else {
      toast.error('No se ha podido programa la llamada');
      navigate(publicRoutes.LOGIN, {
        replace: true
      });
    }
  };

  useEffect(() => {
    const currentTimePlusOneHour = getCurrentTimePlusOneHour();
    setCustomTime(currentTimePlusOneHour[1]);
    setLabelTime(`${currentTimePlusOneHour[0]} ${currentTimePlusOneHour[1].format('HH:mm')}`);
  }, []);

  useEffect(() => {
    if (!updatePhone.isLoading && !updatePhone.error && updatePhone.data) {
      if (updatePhone.data.status == 'ok') {
        setPhoneValidate(true);
        return;
      } else {
        toast.error('No se ha podido actualizar el telefono');
      }
    }
    if (updatePhone.error) {
      toast.error('No se ha podido actualizar el telefono');
    }
  }, [updatePhone]);

  return (
    <div className="page-content header-clear-medium">
      <div className="card card-style">
        <div className="content">
          {callScheduled ? (
            <>
              <h4 className="opacity-60  m-3">
                Llamada programada correctamente. Te llamaremos {`${finalDataSelected.isTomorrow() ? 'mañana ' : ''}`}{' '}
                {finalDataSelected.format('HH:mm') == dayjs().format('HH:mm')
                  ? 'en unos minutos'
                  : 'a las ' + finalDataSelected.format('HH:mm')}
                , para ayudarte con tu caso.
              </h4>
              <Link to={publicRoutes.LOGIN} className="btn btn-lg btn-full font-600 gradient-blue m-2">
                Volver
              </Link>
            </>
          ) : phoneValidate ? (
            <>
              <h4 className="opacity-60 text-center">¿A qué hora quieres que te llamemos para comentar tu caso?</h4>
              {!showTimePicker && (
                <div className="mt-4">
                  <a
                    href="#"
                    onClick={() => {
                      scheduleCallToMessage();
                    }}
                    data-menu="quiz-2"
                    className="btn btn-xl btn-full m-2 font-600 gradient-blue">
                    AHORA
                  </a>
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      setShowTimePicker(true);
                    }}
                    data-menu="quiz-2"
                    className="btn btn-xl btn-full m-2 font-600 gradient-blue">
                    Definir otra hora
                  </a>
                </div>
              )}
              {showTimePicker && (
                <>
                  <div className="time-picker input-style has-borders m-2">
                    <span className="mr-2">{labelTime}</span>
                    <input
                      type="time"
                      value={customTime.format('HH:mm')}
                      min="08:00"
                      max="20:00"
                      onChange={({ target }) => {
                        const now = dayjs();
                        const hourSelected = target.value.split(':');
                        console.log(hourSelected);
                        if (hourSelected[0] < HOURS_WORK.start || hourSelected[0] > HOURS_WORK.end) {
                          toast.error('El horario de atención es de 8:00 a 21:00');
                          return;
                        }
                        const hour = dayjs().clone(now).hour(hourSelected[0]).minute(hourSelected[1]);
                        if (hour.isBefore(now)) {
                          setCustomTime(hour.add(1, 'day'));
                          setLabelTime(`Mañana ${target.value}`);
                        } else {
                          setCustomTime(hour);
                          setLabelTime(`Hoy ${target.value}`);
                        }
                      }}
                    />
                  </div>

                  <a href="#" onClick={scheduleCustomHour} className="btn btn-xl btn-full m-2 font-600 gradient-blue">
                    Confirmar
                  </a>
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      setShowTimePicker(false);
                    }}
                    className="btn btn-xl btn-full m-2 font-600 btn-border color-blue-dark bg-theme">
                    Volver
                  </a>
                </>
              )}
            </>
          ) : (
            <>
              <ValidatePhone setPhoneValidate={setPhoneValidate} token={token} />
            </>
          )}
        </div>
      </div>
    </div>
  );
}
