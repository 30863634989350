import IconCellphone from '@assets/img/cellphone.png';
import IconEmailRollback from '@assets/img/email-rollback.png';
import IconEmailCheck from '@assets/img/emailCheck.png';
import IconError from '@assets/img/error.png';
import IconLoading from '@assets/img/loading.png';
import IconSendEmail from '@assets/img/send.png';
import propTypes from 'prop-types';

import useAuth from '@hooks/useAuth';
import { privateRoutes, publicRoutes } from '@routes';
import { Link } from 'react-router-dom';

function MessageIcon({ icon, message }) {
  const auth = useAuth();
  const icons = {
    loading: IconLoading,
    error: IconError,
    sendEmail: IconSendEmail,
    changeEmailSuccess: IconEmailCheck,
    changePhoneSuccess: IconCellphone,
    rollbackEmailSuccess: IconEmailRollback
  };
  const messages = {
    loading: { title: 'Cargando...', description: 'Estamos procesando tu solicitud.' },
    sendChangeEmail: {
      title: '¡Muy bien!',
      description: 'Te hemos enviado un correo con un link para validar tu identidad y finalizar el cambio.'
    },
    changeEmailSuccess: { title: '¡Perfecto!', description: 'Has modificado tu correo.' },
    changeEmailError: { title: '¡Uuups!', description: 'Tuvimos problemas al modificar tu correo.' },
    changePhoneSuccess: { title: '¡Perfecto!', description: 'Has modificado tu teléfono.' },
    changePhoneError: { title: '¡Uuups!', description: 'Tuvimos problemas al modificar tu teléfono.' },
    rollbackEmailSuccess: { title: '¡Perfecto!', description: 'Has recuperado tu correo.' }
  };

  return (
    <div className="card card-style card-mw-500 mx-sm-auto">
      <div className="content">
        <img className="rounded mx-auto d-block m-4 wh-150 a-fade" src={icons[icon]}></img>
        <h1 className="text-center fs-2">{messages[message].title}</h1>
        <p className="fs-6 text-center mb-3 mt-3 border-light border-bottom border-2 pb-2">
          {messages[message].description}
        </p>
        {auth.token ? (
          <Link
            to={privateRoutes.HOME}
            replace={true}
            className="default-link btn btn-m rounded-sm font-700 btn-full gradient-blue border-0">
            Volver
          </Link>
        ) : (
          <Link
            to={publicRoutes.LOGIN}
            replace={true}
            className="default-link btn btn-m rounded-sm font-700 btn-full gradient-blue border-0">
            Accede a Mi elAbogado
          </Link>
        )}
      </div>
    </div>
  );
}

MessageIcon.propTypes = {
  icon: propTypes.string,
  message: propTypes.string
};

export default MessageIcon;
