import propTypes from 'prop-types';
import { useState } from 'react';

const BASE_URL_PUBLIC_API = process.env.REACT_APP_URL_PUBLIC_HOST;

function LocationSearch({ callback, value }) {
  const [locations, setLocations] = useState([]);
  const [locationName, setLocationName] = useState(value ? value.name : locationName);
  const [openSelectorLocation, setOpenSelectorLocation] = useState(false);
  const isValid = locationName && locationName.length > 0;
  const [validSelection, setValidSelection] = useState(isValid);

  async function getLocationsByName() {
    const resultRequest = await fetch(
      `${BASE_URL_PUBLIC_API}/message/create/location/autocomplete?pattern=${locationName}`
    );
    if (resultRequest && resultRequest.ok) {
      const response = await resultRequest.json();
      setLocations(response);
      setOpenSelectorLocation(true);
    }
  }
  return (
    <>
      <div className="input-style has-icon  has-borders validate-field">
        <i className="fa fa-search"></i>

        <input
          type="text"
          placeholder="Escribe aquí tu localidad"
          value={locationName}
          onChange={(e) => {
            let name = e.target.value;
            setLocationName(name);
            setValidSelection(false);
            if (name.length > 0) {
              getLocationsByName(name);
            } else {
              setLocations([]);
              setOpenSelectorLocation(false);
              setValidSelection(false);
            }
          }}
          data-search=""
        />
        {validSelection ? (
          <i className={`fa fa-check  valid color-green-dark`}></i>
        ) : (
          <i className={`fa fa-times  invalid color-red-dark`}></i>
        )}
      </div>

      <div className={`search-results ${!openSelectorLocation && 'disabled'}`}>
        <div className="list-group list-custom-large">
          {locations.map((location) => (
            <a
              key={location.id}
              onClick={() => {
                setLocationName(location.name);
                setOpenSelectorLocation(false);
                callback({ id: location.id, name: location.name });
                setValidSelection(true);
              }}>
              <span>{location.name}</span>
            </a>
          ))}
        </div>
      </div>
    </>
  );
}
LocationSearch.propTypes = {
  callback: propTypes.func,
  value: propTypes.object
};

export default LocationSearch;
