import { useEffect, useState } from 'react';

export default function apiFetch(service) {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const resultRequest = await service();
        if (resultRequest && resultRequest.status === 200) {
          setData(resultRequest.data);
        } else {
          setError(true);
          setError('General Error');
        }
      } catch (errorAPI) {
        setError(true);
        setErrorMessage(errorAPI);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  return { data, isLoading, error, errorMessage };
}
