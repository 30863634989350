import PrivatePage from '@components/common/PrivatePage';
import useApi from '@hooks/api/useApi';
import useAuth from '@hooks/useAuth';
import { privateRoutes } from '@routes';
import authService from '@services/Auth.service';
import { useEffect, useState } from 'react';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { useNavigate } from 'react-router-dom';
import Errors, { ICON_WARNING } from 'utils/Errors';

const DEFAULT_LOCALE = process.env.REACT_APP_DEFAULT_LOCALE;
const DEFAULT_COUNTRY_PHONE_CODE = process.env.REACT_APP_DEFAULT_COUNTRY_PHONE_CODE;

function ChangePhone() {
  const { userData } = useAuth();
  const sendSMS = useApi();
  const [phone, setPhone] = useState('');
  const error = Errors();

  let navigate = useNavigate();

  useEffect(() => {
    if (!sendSMS.isLoading && !sendSMS.error && sendSMS.data) {
      if (sendSMS.data.sentCode) {
        navigate(privateRoutes.PROFILE_PHONE_VERIFICATION, {
          replace: true,
          state: {
            phone: phone.replace(DEFAULT_COUNTRY_PHONE_CODE, '')
          }
        });
      }
    }
  }, [sendSMS]);

  async function changePhoneNumber() {
    if (userData.phone != phone) {
      sendSMS.call(authService.sendCodeSMSToPhone(phone.replace(DEFAULT_COUNTRY_PHONE_CODE, '')));
    } else {
      error.build(
        ICON_WARNING,
        'Uuups!',
        'Parece que el numero que quieres cambiar es el mismo que tienes actualmente.',
        privateRoutes.PROFILE_PHONE
      );
    }
  }

  return (
    <PrivatePage title="Modificar télefono">
      <div className="page-content">
        <div className="card card-style card-mw-500 mx-sm-auto">
          <div className="content">
            <p className="mb-3">Ingresa tu nuevo número. Te enviaremos un SMS para confirmar tu identidad.</p>

            <PhoneInput
              className="d-flex"
              inputClassName="w-100 p-4"
              disableFormatting={true}
              countrySelectorStyleProps={{ buttonClassName: 'py-4 px-2' }}
              defaultCountry={DEFAULT_LOCALE.split('_')[0].toLowerCase()}
              value={phone}
              onChange={(phone) => setPhone(phone)}
            />

            <button
              style={{ width: '100%' }}
              type="submit"
              onClick={changePhoneNumber}
              className={`btn btn-full btn-l font-600 font-13 gradient-highlight mt-4 rounded-s `}>
              Continuar
            </button>
          </div>
        </div>
      </div>
    </PrivatePage>
  );
}

export default ChangePhone;
