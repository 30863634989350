import propTypes from 'prop-types';
import { MenuSideBarContext } from '@components/common/Sidebar';
import { useContext } from 'react';

function PageTitle({ title, logo }) {
  const updateStatusMenu = useContext(MenuSideBarContext);
  return (
    <div className="page-title">
      {logo ? <h1 className="logo-header">{logo}</h1> : <h1>{title}</h1>}
      <a
        onClick={(e) => {
          e.preventDefault();
          updateStatusMenu();
        }}
        className="page-title-icon shadow-xl bg-theme color-theme">
        <i className="fa fa-bars"></i>
      </a>
    </div>
  );
}

PageTitle.propTypes = {
  title: propTypes.string,
  logo: propTypes.bool
};

export default PageTitle;
