import StarRating from '@components/common/StarRating';
import propTypes from 'prop-types';

function Description({ lawyerInfo }) {
  return (
    <div className="content">
      <StarRating rating={lawyerInfo.rating} totalOpinions={lawyerInfo.totalOpinions} />
      <p className="mb-n3">{lawyerInfo.description}</p>
      <br />
      <a href={`tel:${lawyerInfo.phone}`} className="font-600 color-highlight" type="">
        Tel.: {lawyerInfo.phone}
      </a>
      <br />
      {lawyerInfo.web && (
        <a href={lawyerInfo.web} className="font-600 color-highlight">
          {lawyerInfo.web}
        </a>
      )}
    </div>
  );
}
Description.propTypes = {
  lawyerInfo: propTypes.object
};
export default Description;
