import FormAccount from '@components/account/FormAccount';
import PrivatePage from '@components/common/PrivatePage';
import '@styles/form.css';
function EditAccount() {
  return (
    <PrivatePage title="Editar mis datos">
      <div className="page-content">
        <FormAccount />
      </div>
    </PrivatePage>
  );
}

export default EditAccount;
