import propTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import PageTitle from './PageTitle';
import { MenuSideBarContext } from './Sidebar';

function PrivatePage({ title, children, logo }) {
  const updateStatusMenu = useContext(MenuSideBarContext);
  const [menuIsOpen, setMenu] = useState(true);

  useEffect(() => {
    if (menuIsOpen) {
      updateStatusMenu(true);
      setMenu(false);
    }
  }, [menuIsOpen]);

  return (
    <>
      <PageTitle title={title} logo={logo} />
      {children}
    </>
  );
}

PrivatePage.propTypes = {
  title: propTypes.string,
  logo: propTypes.bool,
  children: propTypes.oneOfType([propTypes.array, propTypes.object])
};
export default PrivatePage;
