import PropTypes from 'prop-types';
import { Toaster } from 'react-hot-toast';
import Footer from '../Footer';
import Header from '../Header';
import PWAInstall from '../PWAInstall';
import Sidebar from '../Sidebar';
import './Layout.css';

function Layout({ children }) {
  return (
    <Sidebar>
      <Toaster />
      <main className="theme-light">
        <div id="page" className="App">
          <Header />
          <div className="page-content pb-lg-0">{children}</div>
          <PWAInstall />
          <Footer />
        </div>
      </main>
    </Sidebar>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
