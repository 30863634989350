import { Link } from 'react-router-dom';

function NewLeadCard() {
  return (
    <Link to="/nuevo-caso" className="card card-style mb-3">
      <div className="m-3">
        <div className="d-flex">
          <div className="align-self-center">
            <i className="fa fa-plus-circle color-green-dark font-32 pe-3 pt-1"></i>
          </div>
          <div className="align-self-center">
            <h5 className="mb-2">Crear nuevo caso</h5>
            <p className="m-0">
              Si tienes un nuevo caso <strong className="color-theme">contacta ahora</strong>.
            </p>
          </div>
        </div>
      </div>
    </Link>
  );
}

export default NewLeadCard;
