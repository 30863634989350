import Loader from '@components/common/Loader';
import propTypes from 'prop-types';
import { useState } from 'react';
const BASE_URL = process.env.REACT_APP_URL_PUBLIC_HOST;

function SpecialtyQuestions({ idMessage = 1024853, firstQuestion, callbackFinish }) {
  const [questionShow, setQuestionShow] = useState({ ...firstQuestion });
  const [loading, setLoading] = useState(false);
  const [closed, setClosed] = useState(null);

  async function updateQuestion({ idQuestion, idAnswer }) {
    setLoading(true);
    const messageRequest = {
      'question.idMessage': idMessage,
      'question.idQuestion': idQuestion,
      'question.idAnswer': idAnswer
    };
    const formData = new FormData();
    for (const name in messageRequest) {
      formData.append(name, messageRequest[name]);
    }
    const resultRequest = await fetch(`${BASE_URL}/message/create/answer`, {
      method: 'POST',
      body: formData
    });
    if (resultRequest && resultRequest.ok) {
      const response = await resultRequest.json();
      if (response.answer.action === 'CALL') {
        callbackFinish();
      } else if (response.answer.action === 'CLOSE') {
        setClosed(response.answer.closingStatement);
      }
      setQuestionShow(response.answer.nextQuestion);
    }
    setLoading(false);
  }
  return closed ? (
    <h4 className="opacity-60">{closed}</h4>
  ) : (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <h4 className="opacity-60">{questionShow.question}</h4>
          {questionShow.answers.map((answer) => {
            return (
              <div key={answer.id}>
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    const idQuestion = answer.idQuestion;
                    const idAnswer = answer.id;
                    updateQuestion({ idQuestion, idAnswer });
                  }}
                  className="btn btn-xl btn-full m-2 font-600 gradient-blue">
                  {answer.answer}
                </a>
              </div>
            );
          })}
        </>
      )}
    </>
  );
}

SpecialtyQuestions.propTypes = {
  idMessage: propTypes.number,
  firstQuestion: propTypes.object,
  callbackFinish: propTypes.func
};
export default SpecialtyQuestions;
