import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import SentryErrorBoundary from './SentryErrorBoundary';

function initializeSentry() {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.1,
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT
  });
}

export { SentryErrorBoundary, initializeSentry };
