import { MIN_LENGTH_PHONE } from '@constants';
import useApi from '@hooks/api/useApi';
import userService from '@services/User.service';
import propTypes from 'prop-types';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

const BASE_URL = process.env.REACT_APP_URL_PUBLIC_HOST;
const DEFAULT_LOCALE = process.env.REACT_APP_DEFAULT_LOCALE;

function AddPhone({ callbackRecentMessage }) {
  const api = useApi();
  const [syncLeads, setSyncLeads] = useState(false);
  const [phone, setPhone] = useState('');
  const [error, setError] = useState(null);
  let [searchParams] = useSearchParams();

  async function createLead() {
    let messageRequest = {
      'message.phone': phone,
      'message.messageType': 'MESSAGE',
      'message.trace.messageType': 'MESSAGE',
      'message.assignationEnabled': 'true',
      'message.trace.source': 'PWA',
      'message.trace.idLanguage': DEFAULT_LOCALE,
      'message.text': ''
    };
    for (const [key, value] of searchParams.entries()) {
      messageRequest = { ...messageRequest, [key]: value };
    }
    const formData = new FormData();
    for (const name in messageRequest) {
      formData.append(name, messageRequest[name]);
    }

    const resultRequest = await fetch(`${BASE_URL}/message/create/phone-json`, {
      method: 'POST',
      body: formData
    });

    if (resultRequest && resultRequest.ok) {
      let { message, callback: resultCreated } = await resultRequest.json();

      if (!syncLeads) {
        setSyncLeads(true);
        api.call(userService.syncLeads());
      }

      if (resultCreated.hasRecenteMessage) {
        callbackRecentMessage();
      } else {
        window.location.href = `${BASE_URL}/message/redirect/?token=${message.token}&chatActive=true`;
      }
    } else {
      setError('Error creating lead');
    }
  }
  return (
    <>
      <h1 className="font-18">¿A qué teléfono quieres que te llame?</h1>
      <div className="input-style has-borders has-icon validate-field mb-4">
        <i className="fa fa-phone"></i>
        <input
          type="tel"
          className="form-control validate-text"
          id="form4"
          placeholder="Teléfono"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          maxLength="15"
        />
        <label htmlFor="form4" className="color-highlight">
          Teléfono
        </label>
        <i className={`fa fa-times ${!error && 'disabled'} invalid color-red-dark`}></i>
        <em className={phone.length > 0 && 'disabled'}>(obligatorio)</em>
      </div>
      <div className="color-red-dark pl-2">{error}</div>
      <a
        onClick={createLead}
        className={`btn btn-full btn-l font-600 font-13 gradient-highlight mt-4 rounded-s ${
          phone.length < MIN_LENGTH_PHONE && 'disabled'
        }`}>
        Siguiente
      </a>
      <div className="box-privacy btn-s my-3">
        <footer>
          Al contactar, acepto expresamente la
          <a target="_blank" rel="noreferrer" href="http://www.elabogado.com/privacidad/">
            Política de privacidad{' '}
          </a>{' '}
          y las
          <a target="_blank" rel="noreferrer" href="http://www.elabogado.com/condiciones-uso/">
            {' '}
            Condiciones de uso
          </a>
          .
        </footer>
      </div>
    </>
  );
}

AddPhone.propTypes = {
  callback: propTypes.func,
  callbackRecentMessage: propTypes.func
};
export default AddPhone;
