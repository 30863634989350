import useApi from '@hooks/api/useApi';
import { User } from '@models/User';
import authService from '@services/Auth.service';
import { useEffect, useState } from 'react';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import registerFlow from 'utils/RegisterFlow';

const DEFAULT_LOCALE = process.env.REACT_APP_DEFAULT_LOCALE;
const DEFAULT_COUNTRY_PHONE_CODE = process.env.REACT_APP_DEFAULT_COUNTRY_PHONE_CODE;

function Register() {
  const sendSMS = useApi();
  const { state } = useLocation();
  const { user, tokenGoogle } = state || {};
  const [phone, setPhone] = useState('');
  let navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      const userModel = User(null, null, null, null, null, false, null, null, null);
      registerFlow(navigate, userModel, null, 'register');
    } else {
      if (user.email && user.phone != null) {
        const userModel = User(null, null, null, null, null, false, null, null, null);
        registerFlow(navigate, userModel, null, 'register');
      }
    }

    if (!sendSMS.isLoading && !sendSMS.error && sendSMS.data) {
      if (sendSMS.data.sentCode) {
        const userModel = User(
          null,
          user.idGoogle,
          user.email,
          user.name,
          phone.replace(DEFAULT_COUNTRY_PHONE_CODE, ''),
          false,
          user.photo,
          null
        );
        registerFlow(navigate, userModel, null, 'register', tokenGoogle);
      }
    }
  }, [sendSMS]);

  async function sendPhoneToGetCodeVerification() {
    sendSMS.call(authService.sendCodeSMSToPhone(phone.replace(DEFAULT_COUNTRY_PHONE_CODE, '')));
  }

  return (
    <div className="page-content header-clear-medium">
      <div className="card card-style">
        <div className="content">
          <p className="font-600 color-highlight mb-1">Nueva cuenta</p>
          <p className="font-600 mb-2 mt-2">Te enviaremos un SMS para verificar que eres tu:</p>
          <PhoneInput
            className="d-flex"
            inputClassName="w-100 p-4"
            disableFormatting={true}
            countrySelectorStyleProps={{ buttonClassName: 'py-4 px-2' }}
            defaultCountry={DEFAULT_LOCALE.split('_')[0].toLowerCase()}
            value={phone}
            onChange={(phone) => setPhone(phone)}
          />

          <button
            onClick={sendPhoneToGetCodeVerification}
            style={{ width: '100%' }}
            type="submit"
            className={`btn btn-full btn-l font-600 font-13 gradient-highlight mt-4 rounded-s `}>
            Enviar SMS
          </button>
          <div className="divider mt-4"></div>
          <Link
            to="/crear"
            className="btn btn-icon text-center btn-full btn-l font-600 font-13 bg-dark-dark mt-2 rounded-s">
            Quiero que me llame un abogado
          </Link>
          <div className="box-privacy btn-s my-3">
            <footer>
              Al contactar, acepto expresamente la
              <a target="_blank" rel="noreferrer" href="http://www.elabogado.com/privacidad/">
                {' '}
                Política de privacidad
              </a>{' '}
              y las
              <a target="_blank" rel="noreferrer" href="http://www.elabogado.com/condiciones-uso/">
                {' '}
                Condiciones de uso
              </a>
              .
            </footer>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Register;
