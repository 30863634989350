import { useEffect } from 'react';
import { Routes, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';
import Layout from '@components/common/Layout';
import PrivateRouting from '@routes/PrivateRouting';
import PublicRouting from '@routes/PublicRouting';
import { SentryErrorBoundary } from '@components/utils/Sentry';

ReactGA.initialize(process.env.REACT_APP_GA_ID);
function App() {
  let location = useLocation();
  useEffect(() => {
    let { pathname } = location;
    const navigationInPWA = window.matchMedia('(display-mode: standalone)').matches;
    if (navigationInPWA) {
      ReactGA.pageview(`${pathname}?pwa=true`);
    } else {
      ReactGA.pageview(pathname);
    }
  }, [location]);

  return (
    <Layout>
      <SentryErrorBoundary>
        <Routes>
          {PublicRouting}
          {PrivateRouting}
        </Routes>
      </SentryErrorBoundary>
    </Layout>
  );
}

export default App;
