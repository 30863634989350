import { useState } from 'react';
import propTypes from 'prop-types';
import useAuth from '@hooks/useAuth';

const BASE_URL = process.env.REACT_APP_URL_PUBLIC_HOST;

function AddEmail({ updateMessage, idMessage }) {
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const { updateUserData, authed } = useAuth();

  async function updateEmail() {
    const formData = new FormData();
    formData.append('message.idMessage', idMessage);
    formData.append('message.email', email);
    const resultRequest = await fetch(`${BASE_URL}/message/create/email`, {
      method: 'POST',
      body: formData
    });

    if (resultRequest && resultRequest.ok) {
      const { message } = await resultRequest.json();
      if (authed) {
        updateUserData({ email });
      }
      updateMessage(message);
    } else {
      setError('Error actualizando el  nombre');
    }
  }
  return (
    <>
      <h1 className="font-18">Dejanos tu email</h1>
      <div className="input-style has-borders has-icon validate-field mb-4">
        <i className="fa fa-at"></i>
        <input
          type="email"
          className="form-control validate-text"
          id="form4"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <label htmlFor="form4" className="color-highlight">
          Email
        </label>
        <i className={`fa fa-times ${!error && 'disabled'} invalid color-red-dark`}></i>
        <em className={email.length > 0 && 'disabled'}>(opcional)</em>
      </div>
      <div className="color-red-dark pl-2">{error}</div>
      <a
        onClick={updateEmail}
        className={`btn btn-full btn-l font-600 font-13 gradient-highlight mt-4 rounded-s`}>
        Siguiente
      </a>
    </>
  );
}
AddEmail.propTypes = {
  updateMessage: propTypes.func,
  idMessage: propTypes.number
};
export default AddEmail;
