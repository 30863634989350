import LoginGoogle from '@components/account/LoginGoogle';
import TextInput from '@components/common/Form/TextInput';
import useApi from '@hooks/api/useApi';
import { publicRoutes } from '@routes';
import authService from '@services/Auth.service';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import * as Yup from 'yup';

function Login() {
  const [magicLink, setMagicLink] = useState(false);
  const [sendEmail, setSendEmail] = useState('');
  const sendMagicLink = useApi();
  const { state } = useLocation();
  const { emailState } = state || {};

  if (!magicLink && emailState) {
    setSendEmail(emailState);
    setMagicLink(true);
  }

  function createMagicLink(data) {
    setMagicLink(true);
    sendMagicLink.call(authService.sendMagicLink(data.email));
    setSendEmail(data.email);
  }

  return (
    <>
      <div className="header-clear-medium"></div>
      <div className="card card-style card-mw-500 mx-auto">
        <div className="content mt-0">
          <div
            className={`rounded mx-auto d-block m-3 wh-150 a-fade ${
              !magicLink ? 'logo-login' : 'logo-send-email'
            }`}></div>
          <h2 className="text-center">{!magicLink ? 'Accede a Mi elAbogado' : 'Tienes un nuevo mensaje'}</h2>
          <p className="text-center mt-n2">
            {!magicLink
              ? 'Introduce tu email para acceder a tu caso'
              : `Confirma tu identidad haciendo click en el enlace que te hemos enviado a ${sendEmail}`}
          </p>

          {!magicLink ? (
            <div>
              <Formik
                initialValues={{
                  email: ''
                }}
                validationSchema={Yup.object({
                  email: Yup.string().email('Invalid email address').required('Required')
                })}
                onSubmit={createMagicLink}>
                <Form>
                  <TextInput icon="fa-light fa-envelope" label="Email" name="email" type="email" placeholder="Email" />

                  <button
                    style={{ width: '100%' }}
                    type="submit"
                    className={`btn btn-full btn-l font-600 font-13 gradient-highlight mt-4 rounded-s 
                ${sendMagicLink.isLoading && 'disabled'}
                `}>
                    Obtén acceso instantáneo
                  </button>
                  <LoginGoogle login={sendMagicLink} title="Inicia sesión con Google" />
                </Form>
              </Formik>
              <div className="row pt-3 mb-3">
                <div className="col-6 text-start font-11"></div>
                <div className="col-6 text-end">
                  <Link
                    className="color-highlight "
                    to={publicRoutes.VERIFICATION_EMAIL}
                    state={{ origin: 'register', user: { name: null } }}>
                    Quiero registrarme
                  </Link>
                </div>
              </div>
              <div className="divider mt-3"></div>
              <Link
                to="/crear"
                className={`btn btn-icon text-center btn-full btn-l font-600 font-13 bg-dark-dark mt-2 rounded-s`}>
                Quiero que me llame un abogado
              </Link>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="box-privacy btn-s mx-3 mb-4">
          <footer>
            Al acceder al panel, acepto expresamente la
            <a target="_blank" rel="noreferrer" href="http://www.elabogado.com/privacidad/">
              {' '}
              Política de privacidad
            </a>{' '}
            y las
            <a target="_blank" rel="noreferrer" href="http://www.elabogado.com/condiciones-uso/">
              {' '}
              Condiciones de uso
            </a>
            .
          </footer>
        </div>
      </div>
    </>
  );
}

export default Login;
