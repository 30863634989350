import PrivatePage from '@components/common/PrivatePage';
import ModalNewLead from '@components/newLead/ModalNewLead';
import OptionNewLead from '@components/newLead/OptionNewLead';
import { PHONE_INBOUND_NUMBER } from '@constants';

import { useState } from 'react';

export default function NewLead() {
  const [openQuestionsModal, setPhoneQuestionsModal] = useState(false);
  let phone = 'tel:' + PHONE_INBOUND_NUMBER;
  return (
    <PrivatePage title="Nuevo Caso">
      <div className="page-content">
        <OptionNewLead
          title="Nuevo Caso"
          description="Si necesitas contactar con un abogado por un nuevo caso, aquí puedes solicitarlo."
          cta={
            <a
              onClick={(e) => {
                e.preventDefault();
                setPhoneQuestionsModal(true);
              }}
              data-menu="quiz-1"
              className="btn btn-xl btn-full gradient-highlight rounded-s font-600 font-13">
              Contactar ahora
            </a>
          }
        />
        <OptionNewLead
          title="Llamar a elAbogado"
          description="Si necesita un abogado, llámenos ahora."
          cta={
            <a
              href={phone}
              className="default-link btn btn-m rounded-sm font-700 text-uppercase btn-full gradient-blue border-0">
              Llamar ahora
            </a>
          }
        />
        <div className="box-privacy btn-s mx-2">
          <footer className="politicaPriv">
            Al hacer clic en “Contactar ahora” o “Llamar ahora”, acepto expresamente la
            <a target="_blank" rel="noreferrer" href="http://www.elabogado.com/privacidad/">
              {' '}
              Política de privacidad
            </a>{' '}
            y las
            <a target="_blank" rel="noreferrer" href="http://www.elabogado.com/condiciones-uso/">
              {' '}
              Condiciones de uso
            </a>
            .
          </footer>
        </div>
      </div>
      <ModalNewLead openQuestionsModal={openQuestionsModal} updateModalState={setPhoneQuestionsModal} />
    </PrivatePage>
  );
}
