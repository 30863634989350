import { URL_ELABOGADO } from '@constants';
import propTypes from 'prop-types';

function Avatar({ url, widthImage, className, rounded, style }) {
  return (
    <img
      src={`${URL_ELABOGADO}${url}`}
      width={widthImage}
      style={style}
      className={`${className} ${rounded && 'rounded-sm'}`}
    />
  );
}

Avatar.propTypes = {
  url: propTypes.string,
  widthImage: propTypes.number,
  rounded: propTypes.bool,
  className: propTypes.string
};

export default Avatar;
