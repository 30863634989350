import Avatar from '@components/lawyer/Avatar';
import Status from '@components/utils/Status';
import '@styles/card.css';
import propTypes from 'prop-types';

function PrincipalDetail({ infoLead }) {
  const isClosed = infoLead.closed;
  return (
    <>
      <div className="card card-fixed" data-card-height="330" style={{ height: 330 }}>
        <div className="card-top p-3">
          <div className="d-flex">
            <div>
              {infoLead?.lawyers?.length > 0 && <h6 className="font-500 color-white pt-2">Abogado/s asignados</h6>}
              <div className="d-flex">
                {infoLead.lawyers.map((lawyer, index) => {
                  let marginLeft = '0';
                  if (index >= 1) {
                    marginLeft = '-10px';
                  }
                  return (
                    <Avatar
                      key={lawyer.id}
                      url={lawyer.urlPicture}
                      className="rounded-circle border border-2 bg-green-dark"
                      style={{ marginLeft: marginLeft }}
                      widthImage={40}
                    />
                  );
                })}
              </div>
            </div>
            <div className="ms-auto text-end">
              <h6 className="font-500 color-white pt-2 mb-0">Fecha</h6>
              <h5 className="mb-1 color-white pt-2">{new Date(infoLead.creationDate).toLocaleDateString()}</h5>
            </div>
          </div>
        </div>
        <div className="card-bottom p-3 pb-5">
          <h1 className="color-white mb-2 line-height-m">
            Caso <br />
            {infoLead.parentSpecialty}
            {infoLead.specialty == infoLead.parentSpecialty ? (
              ''
            ) : (
              <>
                <br></br>
                {infoLead.specialty}
              </>
            )}
          </h1>
          <div className="d-flex">
            <div>
              <h6 className="color-white font-500 pb-2 pt-2 mb-0">
                {!isClosed ? (
                  <>
                    <i className="fa fa-check-circle color-green-dark pe-1"></i>
                    <Status statusLiteral={infoLead.status} showColor={false} />
                  </>
                ) : (
                  <>
                    <i className="fa fa-certificate pe-1 text-warning"></i>
                    <Status statusLiteral={infoLead.status} showColor={false} />
                  </>
                )}
              </h6>
            </div>
            <div className="ms-auto">
              <h6 className="color-white font-500 pb-2 pt-2 mb-0">
                <i className="fa fa-tag text-warning pe-1"></i>
                {infoLead.parentSpecialty}
              </h6>
            </div>
          </div>
        </div>

        <div className="card-overlay bg-gradient"></div>
        <div className="card-overlay bg-blue-dark opacity-90"></div>
      </div>
      <div className="card card-clear" data-card-height="330" style={{ height: 330 }}></div>
    </>
  );
}

PrincipalDetail.propTypes = {
  infoLead: propTypes.object
};

export default PrincipalDetail;
