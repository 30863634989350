import Status from '@components/utils/Status';
import { privateRoutes } from '@routes';
import { SplideSlide } from '@splidejs/react-splide';
import '@styles/card.css';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './LeadCarousel.css';

function LeadCarousel({ lead }) {
  return (
    <SplideSlide
      className="splide__slide is-active is-visible"
      id="double-slider-1-slide04"
      aria-hidden="false"
      tabIndex="0">
      <Link
        to={`${privateRoutes.LEAD}/${lead.id}`}
        className="card card-style me-0 mb-0"
        style={{ height: 160 }}
        data-card-height="160">
        <div id="cardCasos" className="card-top px-2 mx-1 pt-1">
          <div className="d-flex">
            <div>
              <span className="color-theme opacity-40 font-600 font-12 text-nowrap">{lead.parentSpecialty}</span>
            </div>
            <div className="ms-auto">
              <span className="color-theme opacity-40 font-500 font-12"></span>
            </div>
          </div>
        </div>
        <div className="card-center px-2 mx-1">
          <h5 className="pb-3 font-700">
            {lead.specialty} <br />
          </h5>
        </div>
        <div className="card-bottom px-2 mx-1 pb-2">
          <div className="d-flex align-items-end">
            <div>
              <span className="d-block font-14 font-500 color-theme opacity-50 mb-2">Estado</span>
              <Status statusLiteral={lead.status} className="line-height-s d-block mt-n3 font-13 pt-1 font-600" />
            </div>
            <div className="ms-auto text-end">
              <span className="d-block font-14 font-500 color-theme opacity-50 mb-2">Fecha</span>
              <span className="d-block font-12 mt-n3 pt-1 color-theme font-600 line-height-s">
                {new Date(lead.creationDate).toLocaleDateString()}
              </span>
            </div>
          </div>
        </div>
      </Link>
    </SplideSlide>
  );
}

LeadCarousel.propTypes = {
  lead: propTypes.object
};

export default LeadCarousel;
