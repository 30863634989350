import { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import useApi from '@hooks/api/useApi';
import useAuth from '@hooks/useAuth';
import authService from '@services/Auth.service';
import { useGoogleLogin } from 'react-google-login';
import { refreshGoogleToken } from 'utils/RefreshGoogleToken';
import { ReactComponent as GoogleLogo } from '../../assets/img/googleLogo.svg';
import googleOneTap from 'google-one-tap';
import jwt_decode from 'jwt-decode';
import registerFlow from 'utils/RegisterFlow';
import { useNavigate } from 'react-router-dom';
import { User } from '@models/User';
//import Errors from 'utils/Errors';

const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

function LoginGoogle({ login, title, state }) {
  const auth = useAuth();
  const loginApi = useApi();
  const navigate = useNavigate();
  //const error = Errors();
  const [errorLogin, setErrorLogin] = useState(false);
  const [userGoogle, setUserGoogle] = useState({});
  const [userTokenGoogle, setUserTokenGoogle] = useState();
  const { phoneState, phoneValidated } = state || {};
  const onLoginSuccess = (response) => {
    let email;
    let token;
    let name;
    let photo;
    let idGoogle;
    if (response.profileObj) {
      email = response.profileObj.email;
      name = response.profileObj.name;
      token = response.tokenId;
      photo = response.profileObj.imageUrl;
      idGoogle = response.googleId;
    } else {
      let jwt_token = jwt_decode(response.credential);
      email = jwt_token.email;
      name = jwt_token.name;
      token = response.credential;
      photo = jwt_token.picture;
      idGoogle = jwt_token.sub;
    }
    //SI HAY ESTADO, VENGO DE REGISTRO, SINO DE LOGIN
    const user = User(null, idGoogle, email, name, phoneState, phoneValidated, photo, null, null);
    setUserGoogle(user);
    setUserTokenGoogle(token);
    if (state) {
      registerFlow(navigate, user, state.token, state.origin, token);
    } else {
      loginApi.call(authService.loginWithTokenGoogle({ email, token }));
      if (response.profileObj) {
        refreshGoogleToken(response);
      }
    }
  };

  const onLoginFailure = (res) => {
    console.log('Login failure. Res:', res);
  };

  const { signIn } = useGoogleLogin({
    onSuccess: onLoginSuccess,
    onFailure: onLoginFailure,
    clientId,
    isSignedIn: true,
    accessType: 'offline'
  });

  const options = {
    client_id: clientId, // required
    auto_select: true, // optional
    cancel_on_tap_outside: true // optional
    //context: 'signin' // optional
  };

  googleOneTap(options, (response) => {
    // Send response to server
    onLoginSuccess(response);
  });

  useEffect(() => {
    if (!loginApi.isLoading) {
      if (loginApi.error) {
        if (!errorLogin) {
          setErrorLogin(true);
          registerFlow(navigate, userGoogle, null, 'register', userTokenGoogle);
        }
      }
      if (loginApi.data) {
        const { token, refreshToken } = loginApi.data;
        if (token) {
          auth.login(token, refreshToken, null, true);
        }
      }
    }
  }, [loginApi]);

  return (
    <button
      onClick={signIn}
      className={`btn btn-full w-100 border text-body btn-s font-600 font-13 bg-white mt-4 rounded-s ${
        login.isLoading && 'disabled'
      }`}>
      <GoogleLogo className="me-2 my-1"></GoogleLogo>
      {title}
    </button>
  );
}

LoginGoogle.propTypes = {
  login: propTypes.object,
  title: propTypes.string,
  state: propTypes.object
};

export default LoginGoogle;
