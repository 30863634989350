import { privateRoutes } from '@routes';

export default [
  {
    title: 'Inicio',
    icon: <i className="fa fa-heart gradient-red color-white" />,
    path: privateRoutes.HOME
  },
  {
    title: 'Mis Casos',
    icon: <i className="fa fa-home gradient-green color-white" />,
    path: privateRoutes.MY_LEADS
  },
  {
    title: 'Nuevo caso',
    icon: <i className="fa fa-heart gradient-magenta color-white" />,
    path: privateRoutes.NEW_LEAD
  },
  {
    title: 'Perfil',
    icon: <i className="fa fa-user gradient-blue color-white" />,
    path: privateRoutes.PROFILE
  }
];
