import useApi from '@hooks/api/useApi';
import authService from '@services/Auth.service';
import { useEffect, useState } from 'react';
import ReactInputVerificationCode from 'react-input-verification-code';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { useSearchParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const DEFAULT_LOCALE = process.env.REACT_APP_DEFAULT_LOCALE;
const DEFAULT_COUNTRY_PHONE_CODE = process.env.REACT_APP_DEFAULT_COUNTRY_PHONE_CODE;

function ValidatePhone({ setPhoneValidate, token }) {
  const MySwal = withReactContent(Swal);
  const [code, setCodeSMS] = useState('');
  const [searchParams] = useSearchParams();
  const [phone, setPhone] = useState(searchParams.get('phone'));
  const [showCodeValidation, setShowCodeValidation] = useState(false);
  const sendSMS = useApi();
  const validateSms = useApi();

  function validateSmsCode(code) {
    validateSms.call(authService.validatePhone(phone.replace(DEFAULT_COUNTRY_PHONE_CODE, ''), code, token));
  }

  useEffect(() => {
    if (!validateSms.isLoading && validateSms.data && validateSms.data?.validPhone && code.length === 6) {
      setCodeSMS('');
      setPhoneValidate(validateSms.data?.validPhone);
    }
    if (validateSms.error || (validateSms.data && !validateSms.data.validPhone)) {
      MySwal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Datos incorrectos, vuelva a intentar'
      });
    }
  }, [validateSms]);

  const validatePhone = () => {
    if (phone == searchParams.get('phone')) {
      setPhoneValidate(true);
      return;
    } else {
      sendSMS.call(authService.sendCodeSMSToPhone(phone.replace(DEFAULT_COUNTRY_PHONE_CODE, '')));
    }
  };

  useEffect(() => {
    if (!sendSMS.isLoading && !sendSMS.error && sendSMS.data) {
      if (sendSMS.data.sentCode) {
        setShowCodeValidation(true);
      }
    }
  }, [sendSMS]);

  const pasteCode = () => {
    navigator.clipboard.readText().then((text) => {
      const regex = /^[0-9]{6}$/;
      if (!regex.test(text)) {
        MySwal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'El texto pegado no es un código de 6 dígitos'
        });
        return;
      }
      setCodeSMS(text);
    });
  };

  return (
    <>
      {showCodeValidation ? (
        <>
          <p className="font-600 color-highlight mb-1">Código SMS</p>
          <p>
            En breve recibirás un SMS de <strong> elAbogado</strong> en el número de teléfono
            <b> {phone.replace(DEFAULT_COUNTRY_PHONE_CODE, '')} </b> con un código para verificar tu identidad.
          </p>
          <div className="verificationCode">
            <ReactInputVerificationCode
              autoFocus="true"
              icon="fa-comment"
              length={6}
              onChange={setCodeSMS}
              onCompleted={validateSmsCode}
              value={code}
              label="Código de verificación"
              name="code"
              type="number"
              placeholder=""
            />
          </div>
          <div onClick={pasteCode} className="text-center pt-4  mt-3 p-3 font-500">
            Pegar
            <i className="fa-regular fa-copy ms-2"></i>
          </div>
          <button
            onClick={() => {
              validateSmsCode(code);
            }}
            style={{ width: '100%' }}
            type="submit"
            className={`btn btn-full btn-l font-600 font-13 gradient-highlight mt-3 rounded-s ${
              (validateSms.isLoading || code.length < 6) && 'disabled'
            }`}>
            Validar código
          </button>
        </>
      ) : (
        <>
          <h5 className="mb-4">Confirmar mi telefono de contacto</h5>
          <PhoneInput
            className="d-flex"
            inputClassName="w-100 p-4"
            disableFormatting={true}
            countrySelectorStyleProps={{ buttonClassName: 'py-4 px-2' }}
            defaultCountry={DEFAULT_LOCALE.split('_')[0].toLowerCase()}
            value={phone ? phone : ''}
            onChange={(phone) => setPhone(phone)}
          />
          <button
            style={{ width: '100%' }}
            type="submit"
            onClick={validatePhone}
            className={`btn btn-full btn-l font-600 font-13 gradient-highlight mt-4 rounded-s `}>
            Continuar
          </button>
        </>
      )}
    </>
  );
}

export default ValidatePhone;
