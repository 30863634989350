import logoElAbogado from '@assets/img/logo-elAbogado.png';
import useAuth from '@hooks/useAuth';
import './Header.css';

function Header() {
  const { authed } = useAuth();
  return (
    <div className={`header ${!authed ? 'header-fixed' : 'header-auto-show'} header-logo-center `}>
      <a href="/" className="header-title">
        <img src={logoElAbogado} width="140" />
      </a>

      {authed && (
        <>
          <a href="#" data-back-button="" className="header-icon header-icon-1">
            <i className="fas fa-chevron-left"></i>
          </a>
          <a href="#" className="header-icon header-icon-4">
            <i className="fas fa-bars"></i>
          </a>
        </>
      )}
    </div>
  );
}

export default Header;
