import PrivatePage from '@components/common/PrivatePage';
import CarouselLeads from '@components/myLeads/CarouselLeads';
import ListLeads from '@components/myLeads/ListLead';
import NewLeadCard from '@components/myLeads/NewLeadCard';
import ResumeLeads from '@components/myLeads/ResumeLeads';
import useApiFetch from '@hooks/api/useFetchApi';
import userService from '@services/User.service';

export default function MyLeads() {
  const fetchLeads = useApiFetch(userService.getLeads());
  return (
    <>
      <PrivatePage title="Mis Casos">
        <div className="content mt-0">
          <div className="row mb-0">
            <ResumeLeads fetchLeads={fetchLeads} />
          </div>
        </div>
        <NewLeadCard />
        <CarouselLeads fetchLeads={fetchLeads} title="Todos mis casos" />
        <ListLeads fetchLeads={fetchLeads} />
      </PrivatePage>
    </>
  );
}
