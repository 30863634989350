import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import useApi from '@hooks/api/useApi';
import AuthService from '@services/Auth.service';
import { useEffect, useState } from 'react';
import Loader from '@components/common/Loader';
import registerFlow from 'utils/RegisterFlow';
import UserService from '@services/User.service';
import { publicRoutes } from '@routes';
import { User } from '@models/User';
import Errors from 'utils/Errors';

function ValidateUser() {
  const error = Errors();
  const apiVerification = useApi();
  const apiRegister = useApi();
  const [apiVerificationCall, setApiVerificationCall] = useState(false);
  const [apiRegisterCall, setApiRegisterCall] = useState(false);
  const { state } = useLocation();
  const { emailState } = state || {};
  let [searchParams] = useSearchParams();
  let token = searchParams.get('token');
  let origin = searchParams.get('origin');
  const navigate = useNavigate();

  useEffect(() => {
    if (!state) {
      if (!apiVerificationCall) {
        setApiVerificationCall(true);
        apiVerification.call(AuthService.validateUser(token, origin));
      }
      if (!apiVerification.isLoading && !apiVerification.error && apiVerification.data) {
        const { phoneValidated, phone, email, name } = apiVerification.data;
        const user = User(null, null, emailState ? emailState : email, name, phone, phoneValidated, null, null, null);
        registerFlow(navigate, user, token, origin);
      }
      if (apiVerification.error) {
        error.generic();
      }
    }
  }, [apiVerification]);

  useEffect(() => {
    if (state) {
      if (!apiRegisterCall) {
        if (state.phoneState && state.emailState && state.phoneValidated) {
          setApiRegisterCall(true);
          apiRegister.call(
            UserService.register(
              state.user.name,
              state.user.photo,
              state.phoneState,
              state.emailState,
              state.phoneValidated,
              state.idGoogle,
              state.token,
              state.origin,
              state.tokenGoogle
            )
          );
        } else {
          error.generic();
        }
      }

      if (!apiRegister.isLoading && !apiRegister.error && apiRegister.data) {
        if (apiRegister.data.tokenOTP) {
          //LOGIN AUTOMATICO
          navigate(`${publicRoutes.MAGIC_LINK}/${apiRegister.data.tokenOTP}?email=${apiRegister.data.email}`, {
            replace: true
          });
        } else {
          //SE ENVIO MAIL CON EL LINK PARA ACCEDER, verificar acceder a magic link con mensaje de envio mail.
          navigate(publicRoutes.LOGIN, {
            state: {
              emailState: apiRegister.data.email
            }
          });
        }
      }
      if (apiRegister.error) {
        error.generic();
      }
    }
  }, [apiRegister]);

  return (
    <div className="header-clear-medium">
      <Loader></Loader>
    </div>
  );
}

export default ValidateUser;
