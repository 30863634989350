import CreateLeadCircuit from '@components/createLeadCircuit';

function CreateLead() {
  return (
    <div className="page-content header-clear-medium">
      <div className="card card-style">
        <div className="content">
          <CreateLeadCircuit></CreateLeadCircuit>
        </div>
      </div>
    </div>
  );
}

export default CreateLead;
