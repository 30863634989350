import propTypes from 'prop-types';

import LawyerCard from '../LawyerCard';

const messagesScreen = {
  IN_PROCESS: {
    title: 'Pendiente de hablar contigo',
    subtitle: 'Hemos recibido tu petición. Ahora procedemos a llamarte para ver tu caso'
  },
  CLOSE: {
    state: 'CLOSE',
    title: 'Este caso está cerrado',
    subtitle:
      'No hemos podido gestionar tu caso, por los motivos que hemos comentado telefónicamente o porque no hemos podido contactar contigo'
  },
  CLOSE_SENT: {
    state: 'CLOSE_SENT',
    title: 'Abogados asignados',
    subtitle: 'Has contactado con los siguientes abogados para llevar este caso.'
  }
};

function ResumeLead({ infoLead }) {
  // const inProcess = infoLead.status === ('WENOWCALL' || 'WEHAVECALLED' || 'INPROCESS') ;
  const isSent = infoLead.lawyers && infoLead.lawyers.length > 0;
  const isClosed = infoLead.closed && !isSent;
  let screenInfo = messagesScreen['IN_PROCESS'];
  if (isSent) {
    screenInfo = messagesScreen['CLOSE_SENT'];
  } else if (isClosed) {
    screenInfo = messagesScreen['CLOSE'];
  }
  return (
    <div className="card card-full rounded-m pb-5">
      {!isClosed && (
        <div className="p-3 pb-0">
          <p className="mb-1 color-highlight font-600 mb-2">Resumen del caso </p>
          <h2 className="mb-3">Descripción</h2>
          <p className="mb-1">{infoLead.description}</p>
        </div>
      )}
      <div className="p-3 pt-0">
        <h4 className="mb-3">{screenInfo.title}</h4>
        <p className="mb-4">{screenInfo.subtitle}</p>
        {isSent && (
          <>
            <div className="mt-3">
              {infoLead.lawyers.map((lawyerInfo) => (
                <div key={lawyerInfo.id}>
                  <LawyerCard widthImage={60} lawyerInfo={lawyerInfo} />
                  <div className="divider mb-3 mt-2" />
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

ResumeLead.propTypes = {
  infoLead: propTypes.object
};

export default ResumeLead;
