import propTypes from 'prop-types';

function OptionNewLead({ title, description, cta }) {
  return (
    <div className="card card-style">
      <div className="content">
        <p className="mb-1 color-highlight font-600">Contactar</p>
        <h2 className="my-3">{title}</h2>
        <p>{description}</p>
        {cta}
      </div>
    </div>
  );
}

OptionNewLead.propTypes = {
  title: propTypes.string,
  description: propTypes.string,
  cta: propTypes.object
};

export default OptionNewLead;
