import { useEffect, useState } from 'react';
import useApi from '@hooks/api/useApi';
import userService from '@services/User.service';
import MessageIcon from '@components/common/MessageIcon';
import * as Yup from 'yup';
import PrivatePage from '@components/common/PrivatePage';
import { Form, Formik } from 'formik';
import TextInput from '@components/common/Form/TextInput';
import Errors from 'utils/Errors';

function ChangeEmail() {
  const error = Errors();
  const sendEmail = useApi();
  const [emailSended, setEmailSended] = useState(false);

  useEffect(() => {
    if (!sendEmail.isLoading && !sendEmail.error && sendEmail.data) {
      if (sendEmail.data.status == 'ok') {
        setEmailSended(true);
      } else {
        error.generic();
      }
    }
    if (sendEmail.error) {
      error.generic();
    }
  }, [sendEmail]);

  async function changeEmail({ email }) {
    sendEmail.call(userService.changeEmail(email));
  }

  return (
    <PrivatePage title="Modificar correo">
      <div className="page-content">
        {emailSended ? (
          <MessageIcon icon="sendEmail" message="sendChangeEmail" />
        ) : (
          <div className="card card-style card-mw-500 mx-sm-auto">
            <div className="content">
              <p className="mb-3">
                Ingresa tu nueva dirección de email. Te enviaremos un correo para confirmar tu identidad.
              </p>
              <Formik
                initialValues={{
                  email: ''
                }}
                validationSchema={Yup.object({
                  email: Yup.string().email('Invalid email address').required('Required')
                })}
                onSubmit={changeEmail}>
                <Form>
                  <TextInput icon="fa-light fa-envelope" label="Email" name="email" type="email" placeholder="Email" />
                  <button
                    style={{ width: '100%' }}
                    type="submit"
                    className={`btn btn-full btn-l font-600 font-13 gradient-highlight mt-4 rounded-s `}>
                    Continuar
                  </button>
                </Form>
              </Formik>
            </div>
          </div>
        )}
      </div>
    </PrivatePage>
  );
}

export default ChangeEmail;
