import React from 'react';
import propTypes from 'prop-types';
import { Navigate, Route } from 'react-router-dom';
import useAuth from '@hooks/useAuth';
import Account from '@pages/Account';
import EditAccount from '@pages/EditAccount';
import Lawyer from '@pages/Lawyer';
import LeadDetail from '@pages/LeadDetail';
import MyLeads from '@pages/MyLeads';
import NewLead from '@pages/NewLead';
import { privateRoutes, publicRoutes } from './';
import Home from '@pages/Home';
import ChangePhone from '@pages/ChangePhone';
import ChangeEmail from '@pages/ChangeEmail';
import ValidateChangePhone from '@pages/ValidateChangePhone';

const ValidateStatusAuth = (props) => {
  const { authed } = useAuth();
  const { component: Component, isRegister } = props;
  if (!authed) {
    return <Navigate to={publicRoutes.LOGIN} />;
  }

  if (!authed.userData.existingUser && !isRegister) {
    return <Navigate to={privateRoutes.REGISTER} />;
  }
  if (authed.userData.existingUser && isRegister) {
    return <Navigate to={privateRoutes.HOME} />;
  }
  return Component;
};

ValidateStatusAuth.propTypes = {
  component: propTypes.object,
  isRegister: propTypes.bool
};

export default [
  <React.Fragment key={2}>
    <Route path="*" element={<Navigate to={privateRoutes.HOME} replace={true} />} />
    <Route path={privateRoutes.HOME} element={<ValidateStatusAuth component={<Home />} />} />
    <Route path={privateRoutes.MY_LEADS} element={<ValidateStatusAuth component={<MyLeads />} />} />
    <Route path={privateRoutes.LEAD}>
      <Route index element={<Navigate to={privateRoutes.MY_LEADS} replace={true} />} />
      <Route path=":idLead" element={<ValidateStatusAuth component={<LeadDetail />} />}></Route>
    </Route>
    <Route path={privateRoutes.NEW_LEAD} element={<ValidateStatusAuth component={<NewLead />} />} />
    <Route path={privateRoutes.PROFILE} element={<ValidateStatusAuth component={<Account />} />} />
    <Route path={privateRoutes.EDIT_PROFILE} element={<ValidateStatusAuth component={<EditAccount />} />} />
    <Route path={privateRoutes.LAWYER}>
      <Route index element={<Navigate to={privateRoutes.MY_LEADS} replace={true} />} />
      <Route path=":lawyer" element={<ValidateStatusAuth component={<Lawyer />} />}></Route>
    </Route>
    <Route
      path={privateRoutes.PROFILE_PHONE_VERIFICATION}
      element={<ValidateStatusAuth component={<ValidateChangePhone />} />}
    />
    <Route path={privateRoutes.PROFILE_PHONE} element={<ValidateStatusAuth component={<ChangePhone />} />} />
    <Route path={privateRoutes.PROFILE_EMAIL} element={<ValidateStatusAuth component={<ChangeEmail />} />} />
  </React.Fragment>
];
