import React from 'react';
import { useField } from 'formik';
import propTypes from 'prop-types';

const TextInput = ({ label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input>. We can use field meta to show an error
  // message if the field is invalid and it has been touched (i.e. visited)
  const [field, meta] = useField(props);
  return (
    <div className="input-style has-borders has-icon validate-field mb-4">
      <i className={`fa ${props.icon}`}></i>
      <input className="form-control " {...field} {...props} />
      <label htmlFor={props.id || props.name} className="color-highlight">
        {label}
      </label>

      {meta.touched && meta.error ? (
        <i className={`fa fa-times  invalid color-red-dark`}></i>
      ) : (
        meta.touched && <i className={`fa fa-check   valid color-green-dark`}></i>
      )}
    </div>
  );
};

TextInput.propTypes = {
  label: propTypes.string,
  id: propTypes.string,
  name: propTypes.string,
  icon: propTypes.string
};

export default TextInput;
