import Button from '@components/common/Button';
import PrivatePage from '@components/common/PrivatePage';
import MenuHome from '@components/home/ListCardsMenu';
import { PHONE_INBOUND_NUMBER } from '@constants';

export default function Home() {
  let phone = 'tel:' + PHONE_INBOUND_NUMBER;
  return (
    <>
      <PrivatePage title="elAbogado" logo={true}>
        <div className="row mb-0">
          <MenuHome />
        </div>
        <div className="box-privacy  btn-m">
          <Button
            href={phone}
            className="default-link btn btn-m rounded-sm font-700 text-uppercase btn-full gradient-blue border-0"
            text="Llamar ahora"
          />
          <div className="box-privacy btn-s mx-2 mt-2">
            <footer className="politicaPriv">
              Al hacer clic “Llamar ahora”, acepto expresamente la
              <a target="_blank" rel="noreferrer" href="http://www.elabogado.com/privacidad/">
                {' '}
                Política de privacidad
              </a>{' '}
              y las
              <a target="_blank" rel="noreferrer" href="http://www.elabogado.com/condiciones-uso/">
                {' '}
                Condiciones de uso
              </a>
              .
            </footer>
          </div>
        </div>
      </PrivatePage>
    </>
  );
}
