import iconElAbogado from '@assets/img/icon-elAbogado.png';
import shareIOSIcon from '@assets/img/share-ios-icon.png';
import useAuth from '@hooks/useAuth';
import { useEffect, useState } from 'react';
import { isAndroid, isChrome, isIOS, osName } from 'react-device-detect';
import ReactGA from 'react-ga';

const NAME_LOCALSTORAGE_TIME = 'elAbogado-PWA-Timeout-Value';
const isAppInstalled = window.matchMedia && window.matchMedia('(display-mode: standalone)').matches;

function PWAInstall() {
  const { authed } = useAuth();
  const [showPWAInstall, setShowPWAInstall] = useState(false);
  const [installPrompt, setInstallPrompt] = useState(null);

  useEffect(() => {
    const lastReviewAppInstaled = localStorage.getItem(NAME_LOCALSTORAGE_TIME);
    let alreadyShowAndRejected = lastReviewAppInstaled;
    if (alreadyShowAndRejected) {
      if (Date.now() - alreadyShowAndRejected >= 24 * 60 * 60 * 1000) {
        alreadyShowAndRejected = false;
        localStorage.removeItem(NAME_LOCALSTORAGE_TIME);
      }
    }
    if (!isAppInstalled && !alreadyShowAndRejected) {
      if ((isAndroid && !authed) || (isIOS && isChrome)) {
        setShowPWAInstall(false);
      } else {
        setShowPWAInstall(true);
      }

      window.addEventListener('beforeinstallprompt', (e) => {
        // eslint-disable-next-line prettier/prettier
        if (!isAppInstalled || !window.navigator.standalone) {
          setInstallPrompt(e);
        }
      });
    }
  }, [authed]);

  const installApp = async () => {
    const eventInstall = installPrompt;
    if (installPrompt) {
      eventInstall.prompt();
      let outcome = await eventInstall.userChoice;
      if (outcome.outcome === 'accepted') {
        ReactGA.event({ category: 'PWA_INSTALLATION', action: `Installed in  ${osName}` });
        setInstallPrompt(null);
      } else {
        ReactGA.event({ category: 'PWA_INSTALLATION', action: `Rejected installation in  ${osName} ` });
      }
    }
  };

  const installNextTime = async () => {
    localStorage.setItem(NAME_LOCALSTORAGE_TIME, Date.now());
    ReactGA.event({ category: 'PWA_INSTALLATION', action: `Rejected installation in  ${osName} ` });
    setShowPWAInstall(false);
  };

  return (
    <div
      id="menu-install"
      className={`menu menu-box-bottom border border-3 py-3 rounded-m ${showPWAInstall && 'menu-active'}`}
      data-menu-effect="menu-parallax">
      <div className="card-top">
        <a onClick={installNextTime} className="close-menu float-end p-3 text-center text-danger">
          <i className="fa-solid fa-circle-xmark fs-1"></i>
        </a>
      </div>
      <div className="boxed-text-s top-25 px-3 ">
        <div className="d-flex align-items-center">
          <img
            className="rounded align-self-top"
            src={iconElAbogado}
            alt="img"
            width={70}
            height={70}
            style={{ boxShadow: '1px 1px 5px 1px rgba(12,12,12,0.2)' }}
          />
          <h4 className="flex-grow-1 ms-3 text-start">Mi elAbogado</h4>
        </div>
        <p className="text-start mt-2">
          Instala Mi elAbogado a tu pantalla de inicio para acceder de forma rápida a tus casos y todos los beneficios
          de <strong>elAbogado</strong>.
        </p>
        <div className="boxed-text-l">
          {isAndroid ? (
            <a
              href="#"
              onClick={installApp}
              className="pwa-install btn btn-m font-600 bg-highlight mb-3 rounded-pill w-100">
              Instalar
            </a>
          ) : (
            <div className="text-start">
              <h4 className="my-1">Pasos para añadir:</h4>
              <p>
                1. Presiona
                <img
                  src={shareIOSIcon}
                  style={{ height: '1.1em', padding: '0px 10px 0px 10px' }}
                  alt="icon add to home screen iOS"
                />
                <br />
                2. Selecciona
                <span>
                  {' "Añadir a pantalla de inicio '}
                  <span
                    style={{
                      borderStyle: 'solid',
                      borderWidth: '1.4px',
                      width: '0.8em',
                      maxWidth: '1em',
                      fontSize: '1.2em',
                      borderRadius: 4,
                      height: '0.8em',
                      maxHeight: '0.8em',
                      display: 'inline-flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      color: '#222',
                      borderColor: '#222'
                    }}>
                    +
                  </span>
                </span>
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default PWAInstall;
