import '@assets/css/card.css';
import { privateRoutes } from '@routes';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';

const ItemMenu = ({ title, subtitle, icon, position, to }) => {
  return (
    <div className={`col ${position}`}>
      <Link to={to} data-back-button="" className="card card-style rounded-m  p-3 mb-3">
        {icon}
        <h4 className="pt-3">{title}</h4>
        <p className="mt-n2 mb-0 color-highlight">{subtitle}</p>
      </Link>
    </div>
  );
};

function MenuHome() {
  return (
    <>
      <ItemMenu
        to={privateRoutes.MY_LEADS}
        position="pe-2"
        title="Mis Casos"
        subtitle="Ver mis casos"
        icon={<i className="fa fa-home color-red-light font-40 icon-50" />}
      />
      <ItemMenu
        to={privateRoutes.PROFILE}
        position="ps-2"
        title="Mi cuenta"
        subtitle="Mis datos"
        icon={<i className="fa fa-cog color-blue-light font-40 icon-50" />}
      />
      <div className="w-100"></div>
      <ItemMenu
        to={privateRoutes.NEW_LEAD}
        position="pe-2"
        title="Nuevo Caso"
        subtitle="Iniciar un nuevo caso"
        icon={<i className="fa fa-plus-circle color-green-light font-40 icon-50" />}
      />
      <div className="w-100"></div>
    </>
  );
}

ItemMenu.propTypes = {
  title: propTypes.string,
  subtitle: propTypes.string,
  icon: propTypes.object,
  position: propTypes.string,
  to: propTypes.string
};
export default MenuHome;
