import propTypes from 'prop-types';
import Avatar from './Avatar';

function CTA({ lawyerInfo }) {
  return (
    <div className="d-flex content mt-0 mb-1">
      <div>
        <Avatar className=" me-3 shadow-xl" widthImage={85} url={lawyerInfo.urlPicture} rounded />
      </div>
      <div className="d-flex align-items-center">
        <h4 className="line-height-l">
          {lawyerInfo.name}
          <i className="fa fa-check-circle color-blue-dark fs-4 ms-2"></i>
        </h4>
      </div>
    </div>
  );
}

CTA.propTypes = {
  lawyerInfo: propTypes.object
};

export default CTA;
