import Loader from '@components/common/Loader';
import SectionTitle from '@components/common/SectionTitle';
import useApiFetch from '@hooks/api/useFetchApi';
import userService from '@services/User.service';
import { Splide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/splide.min.css';
import propTypes from 'prop-types';
import './CarouselLeads.css';
import LeadCarousel from './ItemCarousel';

function CarouselLeads({ fetchLeads: leads, title }) {
  const fetchLeads = !leads ? useApiFetch(userService.getLeads()) : leads;
  return fetchLeads.isLoading ? (
    <Loader />
  ) : (
    <>
      {fetchLeads.data.content.length > 0 && (
        <>
          {title && <SectionTitle text={title} />}
          <Splide
            options={{
              type: 'slide',
              autoplay: true,
              interval: 2000,
              arrows: false,
              perPage: 2,
              pagination: false
            }}>
            {fetchLeads.data.content.map((lead, index) => {
              return <LeadCarousel lead={lead} key={index} />;
            })}
          </Splide>
        </>
      )}
    </>
  );
}
CarouselLeads.propTypes = {
  fetchLeads: propTypes.object
};
export default CarouselLeads;
