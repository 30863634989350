import { useState, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import useAuth from '@hooks/useAuth';
import useApi from '@hooks/api/useApi';
import authService from '@services/Auth.service';
import Loader from '@components/common/Loader';
import Errors from 'utils/Errors';

function MagicLink() {
  const [magicLinkToken, setMagicLinkToken] = useState(false);
  const loginApi = useApi();
  const auth = useAuth();
  const error = Errors();

  let [searchParams] = useSearchParams();
  let email = searchParams.get('email');
  let { token } = useParams();
  if (token !== undefined && !magicLinkToken) {
    setMagicLinkToken(token ? token : false);
  }

  useEffect(() => {
    if (!loginApi.isLoading && !loginApi.error && magicLinkToken) {
      if (loginApi.data) {
        const { token, refreshToken } = loginApi.data;
        if (token) {
          auth.login(token, refreshToken, null, true);
        }
      } else {
        loginApi.call(authService.loginWithTokenML({ email, token }));
      }
    }
    if (loginApi.error) {
      error.generic();
    }
  }, [loginApi]);

  return (
    <div className="header-clear-medium">
      <Loader />
    </div>
  );
}

export default MagicLink;
