import { createContext } from 'react';
import propTypes from 'prop-types';
import useProvideAuth from '@hooks/useProvideAuth';

export const authContext = createContext();

export function AuthProvider({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

AuthProvider.propTypes = {
  children: propTypes.object
};
