import { publicRoutes } from '@routes';

function registerFlow(navigate, user, token, origin, tokenGoogle) {
  let urlNavigate = publicRoutes.LOGIN;

  if ('register' != origin) {
    if (user.phoneValidated) {
      if (user.email) {
        urlNavigate = publicRoutes.VERIFICATION;
      } else {
        urlNavigate = publicRoutes.VERIFICATION_EMAIL;
      }
    } else {
      urlNavigate = publicRoutes.VERIFICATION_SMS;
    }
  } else {
    if (user.email) {
      if (user.phoneValidated) {
        urlNavigate = publicRoutes.VERIFICATION;
      } else {
        if (user.phone) {
          urlNavigate = publicRoutes.VERIFICATION_SMS;
        } else {
          urlNavigate = publicRoutes.REGISTER;
        }
      }
    } else {
      urlNavigate = publicRoutes.VERIFICATION_EMAIL;
    }
  }

  navigate(urlNavigate, {
    replace: true,
    state: {
      phoneValidated: user.phoneValidated,
      phoneState: user.phone,
      emailState: user.email,
      idGoogle: user.idGoogle,
      user,
      token,
      origin,
      tokenGoogle
    }
  });
}

export default registerFlow;
