import Loader from '@components/common/Loader';
import propTypes from 'prop-types';
import { useEffect, useState } from 'react';

const ResumeLead = ({ title, subtitle, className }) => {
  const defaultStyle = 'card card-style gradient-blue m-0';
  return (
    <div className={className ? className : defaultStyle} style={{ height: 130 }} data-card-height="130">
      <div className="p-3 d-flex flex-column flex-grow-1">
        <h6 className="color-white flex-grow-1">{title}</h6>
        <h2 className="color-white m-0 p-0">{subtitle}</h2>
      </div>
    </div>
  );
};

function ResumeLeads({ fetchLeads }) {
  const [totalLeads, setTotalLeads] = useState('');
  const [totalOpenLeads, setTotalOpenLeads] = useState('');

  useEffect(() => {
    if (!fetchLeads.isLoading && fetchLeads.data.content) {
      const totalLeadsCount = fetchLeads.data.content.length;
      setTotalLeads(`${totalLeadsCount} ${totalLeadsCount > 1 ? 'casos' : 'caso'}`);
      const totalOpenLeadsCount = fetchLeads.data.content.filter(
        (lead) => lead.status === 'WENOWCALL' || lead.status === 'WEHAVECALLED' || lead.status === 'INPROCESS'
      ).length;
      setTotalOpenLeads(`${totalOpenLeadsCount} ${totalOpenLeadsCount > 1 ? 'Casos abiertos' : 'Caso abierto'}`);
    }
  }, [fetchLeads]);

  return (
    <>
      <div className="col-6 pe-2">
        {fetchLeads.isLoading ? <Loader /> : <ResumeLead title="Mis casos" subtitle={totalLeads} />}
      </div>
      <div className="col-6 ps-2">
        {fetchLeads.isLoading ? (
          <Loader />
        ) : (
          <ResumeLead
            title="Casos en curso"
            subtitle={totalOpenLeads}
            className="card card-style gradient-green mx-0 m-0"
          />
        )}
      </div>
    </>
  );
}
ResumeLeads.propTypes = {
  fetchLeads: propTypes.object
};
ResumeLead.propTypes = {
  title: propTypes.string,
  subtitle: propTypes.string,
  className: propTypes.string
};

export default ResumeLeads;
