import Loader from '@components/common/Loader';
import useApi from '@hooks/api/useApi';
import useFetchPublicApi from '@hooks/api/useFetchPublicApi';
import useAuth from '@hooks/useAuth';
import userService from '@services/User.service';
import propTypes from 'prop-types';
import { useState } from 'react';

const BASE_URL = process.env.REACT_APP_URL_PUBLIC_HOST;
const DEFAULT_LOCALE = process.env.REACT_APP_DEFAULT_LOCALE;

function AddSpecialty({ callback, callbackRecentMessage, message, skipSpecialty }) {
  const api = useApi();
  const [syncLeads, setSyncLeads] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const specialties = useFetchPublicApi('/message/create/top-specialties');
  const { phone, userData } = useAuth();
  const [disableButtons, setDisableButtons] = useState(false);

  async function createLead(idSpecialty) {
    const messageRequest = {
      'message.phone': phone ? phone : message.phone,
      'message.messageType': 'MESSAGE',
      'message.trace.messageType': 'MESSAGE',
      'message.trace.source': 'PWA',
      'message.assignationEnabled': 'true',
      'message.trace.idLanguage': DEFAULT_LOCALE,
      'message.text': ''
    };
    if (idSpecialty) {
      messageRequest['message.idSpecialty'] = idSpecialty;
    }
    if (userData) {
      if (userData.idLocation) {
        messageRequest['message.idLocation'] = userData.idLocation;
      }
      if (userData.name) {
        messageRequest['message.name'] = userData.name;
      }
      if (userData.email) {
        messageRequest['message.email'] = userData.email;
      }
    }
    const formData = new FormData();
    for (const name in messageRequest) {
      formData.append(name, messageRequest[name]);
    }

    const resultRequest = await fetch(`${BASE_URL}/message/create/phone-json`, {
      method: 'POST',
      body: formData
    });

    if (resultRequest && resultRequest.ok) {
      let { message, callback: resultCreated } = await resultRequest.json();

      if (!syncLeads) {
        setSyncLeads(true);
        api.call(userService.syncLeads());
      }

      if (resultCreated.hasRecenteMessage) {
        callbackRecentMessage();
      } else {
        window.location.href = `${BASE_URL}/message/redirect/?token=${message.token}&chatActive=true`;
      }
    }
  }

  async function updateSpecialty(idSpecialty) {
    const formData = new FormData();
    formData.append('message.idMessage', message.idMessage);
    formData.append('message.idSpecialty', idSpecialty);

    const resultRequest = await fetch(`${BASE_URL}/message/create/specialty`, {
      method: 'POST',
      body: formData
    });
    if (resultRequest && resultRequest.ok) {
      const { message, question } = await resultRequest.json();
      callback(message, question);
    }
  }

  return (
    <>
      <h4 className="opacity-60 mb-4 text-center">¿Para qué tema necesitas un abogado?</h4>
      {specialties.isLoading ? (
        <Loader />
      ) : (
        <>
          {specialties.data.slice(0, 4).map(({ name, idSpecialty }) => {
            return (
              <div key={idSpecialty}>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    if (disableButtons) return;
                    setDisableButtons(true);
                    if (!message) {
                      createLead(idSpecialty);
                    } else {
                      updateSpecialty(idSpecialty);
                    }
                  }}
                  data-menu="quiz-2"
                  className="btn btn-xl btn-full m-2 font-600 gradient-blue">
                  {disableButtons && <Loader color="white" />}
                  {!disableButtons && <>{name}</>}
                </a>
              </div>
            );
          })}
          <div className={!showAll && `disabled`}>
            {specialties.data.slice(4).map(({ name, idSpecialty }) => {
              return (
                <div key={idSpecialty}>
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      if (disableButtons) return;
                      setDisableButtons(true);
                      if (!message) {
                        createLead(idSpecialty);
                      } else {
                        updateSpecialty(idSpecialty);
                      }
                    }}
                    data-menu="quiz-2"
                    className="btn btn-xl btn-full m-2 font-600 gradient-blue">
                    {disableButtons && <Loader color="white" />}
                    {!disableButtons && <>{name}</>}
                  </a>
                </div>
              );
            })}
          </div>
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              if (!showAll) {
                setShowAll(true);
              } else {
                skipSpecialty(true);

                if (!message) {
                  createLead(null);
                } else {
                  updateSpecialty(0);
                }
              }
            }}
            data-menu="quiz-2"
            className="btn btn-xl btn-full m-2 font-600 gradient-blue">
            {!showAll ? 'Ver más' : 'Niguna de estas'}
          </a>
        </>
      )}
    </>
  );
}

AddSpecialty.propTypes = {
  callback: propTypes.func,
  callbackRecentMessage: propTypes.func,
  message: propTypes.object,
  skipSpecialty: propTypes.func
};

export default AddSpecialty;
