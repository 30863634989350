import propTypes from 'prop-types';

function StarRating({ rating, totalOpinions }) {
  const maxStars = 5;
  if (rating) {
    return (
      <span>
        {[...new Array(maxStars)].map((arr, index) => {
          const givenRating = index + 1;
          return (
            <i
              key={index}
              className={`fa fa-star  ${
                givenRating < rating || givenRating === rating
                  ? 'color-yellow-dark'
                  : 'color-gray-dark'
              }`}></i>
          );
        })}
        <span className="ml-4 font-14 font-400 color-theme opacity-50">{`(${totalOpinions})`}</span>
      </span>
    );
  } else {
    return <></>;
  }
}

StarRating.propTypes = {
  rating: propTypes.number,
  totalOpinions: propTypes.number
};

export default StarRating;
