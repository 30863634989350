import { useState } from 'react';

export default function api() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const call = async (service) => {
    try {
      setIsLoading(true);
      const resultRequest = await service();
      if (resultRequest && resultRequest.status.toString().startsWith(2)) {
        setData(resultRequest.data);
      } else {
        setError(true);
        setError('General Error');
      }
    } catch (error) {
      setError(true);
      setErrorMessage(error);
    } finally {
      setIsLoading(false);
    }
  };

  return { data, call, isLoading, error, errorMessage, setData };
}
