import { publicRoutes } from '@routes';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

export const ICON_SUCCESS = 'success';
export const ICON_ERROR = 'error';
export const ICON_WARNING = 'warning';
export const ICON_INFO = 'info';
export const ICON_QUESTION = 'question';

function Errors() {
  const navigate = useNavigate();
  const MySwal = withReactContent(Swal);

  function generic() {
    MySwal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Hubo un error, vuelva a intentar'
    }).then(() => {
      navigate(publicRoutes.LOGIN);
    });
  }

  function build(icon, title, text, to) {
    MySwal.fire({
      icon: icon,
      title: title,
      text: text
    }).then(() => {
      navigate(to);
    });
  }

  return { generic, build };
}

export default Errors;
